<template>
    <div>
        <ModalBasic
            :title="'Invite User'"
            :modal-open="modals.invite.state.isOpen"
            @close-modal="modals.invite.state.isOpen = false"
        >
            <FormKit v-slot="{ state: inviteUserFormState }" type="group">
                <div
                    class="p-6"
                    :class="{
                        'pointer-events-none opacity-50': modals.invite.state.is_busy,
                    }"
                >
                    <p class="mb-8 text-sm">
                        This will send an email to the address you specify below with an invitation to join your
                        organization.
                    </p>

                    <div class="mb-4 text-sm">
                        <div class="flex gap-6">
                            <div class="flex-1 items-center">
                                <FormKit
                                    v-model="modals.invite.values.first_name"
                                    type="text"
                                    name="first-name"
                                    label="First Name"
                                    validation="required:trim"
                                    validation-visibility="dirty"
                                />
                            </div>
                            <div class="flex-1 items-center">
                                <FormKit
                                    v-model="modals.invite.values.last_name"
                                    type="text"
                                    name="last-name"
                                    label="Last Name"
                                    validation="required:trim"
                                    validation-visibility="dirty"
                                />
                            </div>
                        </div>

                        <div class="my-4">
                            <FormKit
                                v-model="modals.invite.values.email"
                                type="email"
                                name="email"
                                label="Email"
                                help="Enter user email"
                                validation="required|email"
                                validation-visibility="dirty"
                            />
                        </div>

                        <div class="my-4">
                            <FormKit
                                v-model="modals.invite.values.organization_operator.role"
                                type="select"
                                name="role"
                                label="Role"
                                placeholder="Select a role"
                                validation="required"
                                :options="inferiorRoles"
                            />
                        </div>
                    </div>
                </div>

                <div class="border-t border-slate-200 px-5 py-4">
                    <div class="flex flex-wrap justify-end space-x-2">
                        <button
                            class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300"
                            @click="onModalClose('invite')"
                        >
                            Cancel
                        </button>

                        <button
                            class="btn-sm"
                            :class="{
                                'pointer-events-none bg-slate-50 text-slate-400': !inviteUserFormState.valid,
                                'bg-indigo-500 text-white hover:bg-indigo-600': inviteUserFormState.valid,
                            }"
                            :disable="!inviteUserFormState.valid"
                            @click="onModalSubmit('invite')"
                        >
                            Invite
                        </button>
                    </div>
                </div>
            </FormKit>
        </ModalBasic>

        <ModalBasic
            :title="'Edit User'"
            :modal-open="modals.edit.state.isOpen"
            @close-modal="modals.edit.state.isOpen = false"
        >
            <div
                class="p-6"
                :class="{
                    'pointer-events-none opacity-50': modals.edit.state.is_busy,
                }"
            >
                <div class="mb-4 text-sm">
                    <div class="flex items-center gap-6">
                        <div class="flex-1">
                            <label class="mb-2 block text-sm font-medium">First Name</label>
                            <input v-model="modals.edit.values.first_name" type="text" class="form-input w-full" />
                        </div>
                        <div class="flex-1">
                            <label class="mb-2 block text-sm font-medium">Last Name</label>
                            <input v-model="modals.edit.values.last_name" type="text" class="form-input w-full" />
                        </div>
                    </div>

                    <!-- <div class="my-4">
                    <label class="block text-sm font-medium mb-2">Email</label>
                    <input
                        type="text"
                        class="form-input w-full"
                        v-model="modals.edit.values.email"
                        placeholder="Enter user email"
                    />
                </div> -->

                    <div v-if="selfOrganizationRole && selfOrganizationRole.key === 'superadmin'" class="my-4">
                        <label class="mb-2 block text-sm font-medium">Role</label>
                        <FormKit
                            v-model="modals.edit.values.organization_operator.role"
                            type="select"
                            :options="inferiorRoles"
                        />
                    </div>
                </div>
            </div>

            <div class="flex justify-between border-t border-slate-200 px-5 py-4">
                <div>
                    <button
                        class="btn-sm bg-rose-500 text-white hover:bg-rose-600"
                        @click="onOperatorAction({ type: 'delete', operator: { id: modals.edit.state.operatorId } })"
                    >
                        Remove
                    </button>
                </div>
                <div class="flex flex-wrap justify-end space-x-2">
                    <button
                        class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300"
                        @click="onModalClose('edit')"
                    >
                        Cancel
                    </button>

                    <button class="btn-sm bg-indigo-500 text-white hover:bg-indigo-600" @click="onModalSubmit('edit')">
                        Save
                    </button>
                </div>
            </div>
        </ModalBasic>

        <ModalBasic
            :title="'Remove User'"
            :modal-open="modals.delete.state.isOpen"
            @close-modal="modals.delete.state.isOpen = false"
        >
            <div
                class="p-6"
                :class="{
                    'pointer-events-none opacity-50': modals.delete.state.is_busy,
                }"
            >
                <div class="mb-4 text-sm">
                    <p>
                        Are you sure you want to remove this user from
                        <span class="font-semibold">{{ organization.name }}</span
                        >? They will no longer be able to access any of your organization's campaigns.
                    </p>
                </div>
            </div>

            <div class="border-t border-slate-200 px-5 py-4">
                <div class="flex flex-wrap justify-end space-x-2">
                    <button
                        class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300"
                        @click="onModalClose('delete')"
                    >
                        Cancel
                    </button>

                    <button class="btn-sm bg-rose-500 text-white hover:bg-rose-600" @click="onModalSubmit('delete')">
                        Remove
                    </button>
                </div>
            </div>
        </ModalBasic>

        <div class="flex-col divide-y rounded-sm border border-slate-100 border-slate-200 bg-white px-6 py-6">
            <!-- Page Header -->
            <div class="flex justify-between pb-12 pt-6">
                <div>
                    <h2 class="text-1xl font-bold text-slate-800 md:text-2xl">User Management</h2>
                    <div class="text-sm">
                        <p v-if="selfOrganizationRole && ['superadmin', 'admin'].includes(selfOrganizationRole.key)">
                            Manage users in your organization and their permissions.
                        </p>
                        <p v-else></p>
                    </div>
                </div>

                <!-- If staff can invite others to organization, not needed. -->
                <div v-if="selfOrganizationRole && ['superadmin', 'admin'].includes(selfOrganizationRole.key)">
                    <button
                        class="btn btn-sm bg-indigo-500 text-white hover:bg-indigo-600"
                        @click="modals.invite.state.isOpen = true"
                    >
                        <font-awesome-icon :icon="['fas', 'user-plus']" class="mr-0.5 h-4 w-4" />
                        <span class="ml-2 hidden xs:block">Invite User</span>
                    </button>
                </div>
            </div>

            <div v-for="(role, roleKey) in roles" :key="roleKey" class="grid grid-cols-12 gap-x-12 py-12">
                <div class="col-span-12 md:col-span-4">
                    <p class="font-semibold text-slate-800">
                        {{ getFromDictionary(`organization.role.${roleKey}`, 'labelPlural') }}
                    </p>
                    <div class="space-y-2 text-sm">
                        <p>{{ getFromDictionary(`organization.role.${roleKey}`, 'description') }}</p>
                    </div>
                </div>

                <div class="col-span-12 flex md:col-span-8">
                    <SkeletonOperatorTable v-if="!state.isLoaded" />

                    <OperatorsTable
                        v-else-if="state.isLoaded && role.operators.length"
                        :operators="role.operators"
                        @action="onOperatorAction"
                    />

                    <div v-else-if="state.isLoaded && role.operators.length === 0" class="m-auto text-sm">
                        No {{ getFromDictionary(`organization.role.${roleKey}`, 'labelPlural') }} in your organization
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import OperatorsTable from '../partials/tables/OperatorsTable.vue'
import SkeletonOperatorTable from '../partials/skeletons/SkeletonOperatorTable.vue'
import ModalBasic from '../components/ModalBasic.vue'

export default {
    name: 'OrganizationManagementSubpage',

    components: {
        ModalBasic,
        OperatorsTable,
        SkeletonOperatorTable,
    },

    props: {
        organization: {
            type: Object,
        },
    },

    data() {
        return {
            roles: {
                superadmin: {
                    key: 'superadmin',
                    operators: [],
                    order: 0,
                },
                admin: {
                    key: 'admin',
                    operators: [],
                    order: 1,
                },
                user: {
                    key: 'user',
                    operators: [],
                    order: 2,
                },
            },

            // operatorsById: {},

            selfOrganizationRole: null,

            state: {
                isLoaded: false,
            },

            modals: {
                invite: {
                    state: {
                        isOpen: false,
                        is_busy: false,
                    },
                    values: {},
                    defaultValues: {
                        email: '',
                        first_name: '',
                        last_name: '',
                        organization_operator: {
                            role: 'user',
                        },
                    },
                },
                edit: {
                    state: {
                        isOpen: false,
                        is_busy: false,
                        operatorId: null,
                    },
                    values: {},
                    defaultValues: {
                        first_name: '',
                        last_name: '',
                        organization_operator: {
                            role: 'user',
                        },
                    },
                },
                delete: {
                    state: {
                        isOpen: false,
                        is_busy: false,
                        operatorId: null,
                    },
                },
            },
        }
    },

    computed: {
        ...mapGetters(['selfOperator', 'selfActiveOrganizationId', 'getFromDictionary', 'isSfStaff']),

        inferiorRoles() {
            if (!this.selfOrganizationRole) return []

            const mappedRoles = _(this.roles)
                .map((role, roleKey) => ({
                    label: this.getFromDictionary(`organization.role.${roleKey}`, 'label'),
                    value: roleKey,
                    order: role.order,
                }))
                .orderBy(['order', 'asc'])
                .value()

            if (this.isSfStaff) {
                return mappedRoles
            }

            return _.filter(mappedRoles, (role) => role.order > this.selfOrganizationRole.order)
        },

        // operatorsByRole() {
        //     return _.groupBy(this.operatorsById, 'organization_operator.role')
        // },
    },

    created() {
        this.getSelfOrganizationRole()
        this.initializeModals()
        this.getOrganization()
    },

    methods: {
        getSelfOrganizationRole() {
            // Assume user by default
            let selfRole = this.roles.user

            // SF Staff is auto superadmin
            if (this.isSfStaff) {
                selfRole = this.roles.superadmin
            } else if (this.selfOperator.organizations) {
                const currentOrganization = _.find(
                    this.selfOperator.organizations,
                    (organization) => organization.id === this.selfActiveOrganizationId,
                )

                if (currentOrganization) {
                    selfRole = this.roles[currentOrganization.organization_operator.role]
                }
            }

            this.selfOrganizationRole = selfRole
        },

        getOrganization() {
            this.$axios
                .get(`/organizations/${this.selfActiveOrganizationId}`, {
                    params: {
                        relations: ['operators'],
                    },
                })
                .then(({ data }) => {
                    _.forEach(this.roles, (role) => {
                        // eslint-disable-next-line no-param-reassign
                        role.operators = []
                    })

                    _.forEach(data.payload.organization.operators, (operator) => {
                        const roleEntry = this.roles[operator.organization_operator.role]

                        console.log(this.selfOrganizationRole)

                        const isSelf = operator.id === this.selfOperator.id

                        // eslint-disable-next-line no-param-reassign
                        operator.custom = {
                            is_self: isSelf,
                            is_editable:
                                !isSelf && (this.isSfStaff || this.selfOrganizationRole.order === 'superadmin'),
                        }

                        roleEntry.operators.push(operator)
                    })

                    // this.operatorsById = _.keyBy(data.payload.organization.operators, 'id')

                    this.state.isLoaded = true
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        resetModalValues(modalKey) {
            this.modals[modalKey].values = _.cloneDeep(this.modals[modalKey].defaultValues)
        },

        onModalClose(modalKey) {
            this.modals[modalKey].state.isOpen = false

            if (_.has(this.modals[modalKey], 'defaultValues')) {
                this.resetModalValues(modalKey)
            }
        },

        initializeModals() {
            _.forEach(this.modals, (modal, modalKey) => {
                if (modal.defaultValues) {
                    this.resetModalValues(modalKey)
                }
            })
        },

        onOperatorAction({ type, operator }) {
            // Close all other modals
            _.forEach(this.modals, (modal) => {
                // eslint-disable-next-line no-param-reassign
                modal.state.isOpen = false

                if (modal.state.operatorId) {
                    this.modals[type].state.operatorId = null
                }
            })

            // If the modal has default values, populate them
            if (type === 'edit') {
                this.modals[type].values = _.cloneDeep(operator)
            }

            this.modals[type].state.isOpen = true
            this.modals[type].state.operatorId = operator.id
        },

        onModalSubmit(modalKey) {
            const modal = this.modals[modalKey]
            modal.state.is_busy = true

            if (modalKey === 'invite') {
                this.$axios
                    .post(`/organizations/${this.organization.id}/operators`, {
                        operator: modal.values,
                    })
                    .then(async () => {
                        // this.operatorsById[data.payload.operator.id] = _.assign(data.payload.operator, {
                        //     organization_operator: data.payload.organizationOperator,
                        // })

                        await this.getOrganization()
                        modal.state.is_busy = false

                        this.onModalClose(modalKey)
                    })
                    .catch((error) => {
                        console.log('Error inviting new user', error)
                    })
            }

            if (modalKey === 'edit') {
                this.$axios
                    .put(`/organizations/${this.organization.id}/operators/${modal.state.operatorId}`, {
                        operator: modal.values,
                    })
                    .then(async () => {
                        // this.operatorsById[data.payload.operator.id] = _.assign(data.payload.operator, {
                        //     organization_operator: data.payload.organizationOperator,
                        // })

                        await this.getOrganization()
                        modal.state.is_busy = false

                        this.onModalClose(modalKey)
                    })
                    .catch((error) => {
                        console.log('Error inviting new user', error)
                    })
            }

            if (modalKey === 'delete') {
                this.$axios
                    .delete(`/organizations/${this.organization.id}/operators/${modal.state.operatorId}`)
                    .then(async () => {
                        // this.operatorsById[data.payload.operator.id] = _.assign(data.payload.operator, {
                        //     organization_operator: data.payload.organizationOperator,
                        // })

                        await this.getOrganization()
                        modal.state.is_busy = false

                        this.onModalClose(modalKey)
                    })
                    .catch((error) => {
                        console.log('Error inviting new user', error)
                    })
            }
        },
    },
}
</script>
