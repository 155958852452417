<template>
    <div class="mt-4 flex rounded-sm border border-slate-200 bg-white px-3 py-3">
        <h5 class="my-auto text-left text-lg text-sm font-medium leading-5 leading-none text-slate-500">
            Content Type
        </h5>
        <div class="ml-5 inline-flex rounded bg-slate-200 p-1 shadow-sm hover:shadow-sm focus:shadow-sm">
            <button
                v-for="option in platformContentTypes[platformId]"
                :key="option.id"
                class="flex rounded px-3 py-0.5 text-xs font-semibold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                :class="{
                    'bg-white': filters.contentType === option.id,
                }"
                @click.prevent="filters.contentType = option.id"
            >
                <span class="capitalize">{{ option.label }}</span>
            </button>
        </div>

        <div
            v-if="fixedDayRange"
            class="ml-auto inline-flex rounded bg-slate-200 p-1 shadow-sm hover:shadow-sm focus:shadow-sm"
            role="group"
        >
            <button
                v-for="metricWindow in metricsWindows"
                :key="metricWindow.dayRange"
                class="flex rounded px-3 py-0.5 text-xs font-semibold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                :class="{
                    'bg-white': filters.dayRange === metricWindow.dayRange,
                }"
                @click.prevent="filters.dayRange = metricWindow.dayRange"
                @click="onGenerateReport(false)"
            >
                <span class="capitalize">{{ getFromDictionary(`superview.metricWindow.${metricWindow.title}`) }}</span>
            </button>
        </div>
    </div>

    <div v-if="!fixedDayRange" class="mt-4 flex rounded-sm border border-slate-200 bg-white px-5 py-4">
        <div class="flex flex-col">
            <div class="flex">
                <label class="mb-auto mt-auto text-sm font-medium">Content Tags</label>

                <CodeSelect
                    style="width: 299px"
                    class="ml-14"
                    title=""
                    code-type="tag"
                    :preload="true"
                    select-mode="single"
                    :max-items="5000"
                    search-placeholder="Search for a tag"
                    :is-caret-enabled="true"
                    :platform="platformId"
                    :display-options="{
                        platform_id: platformId,
                        content_type: filters.contentType,
                        metrics_type: 'channels',
                        sort_by: `platforms.${platformId}.metrics.${filters.contentType}.channels`,
                        sort_dir: 'desc',
                    }"
                    id-field="name"
                    no-results-text="All matches selected"
                    @selection-change="(item) => onTagChange(item)"
                />
            </div>

            <div v-if="['twitch', 'youtube'].includes(platformId)" class="mt-4 flex">
                <label class="mb-auto mt-auto text-sm font-medium">Game</label>

                <CodeSelect
                    style="width: 299px"
                    class="ml-14"
                    title=""
                    code-type="game"
                    :preload="true"
                    select-mode="single"
                    :max-items="5000"
                    search-placeholder="Search for a game"
                    :is-caret-enabled="true"
                    :platform="platformId"
                    :display-options="{
                        platform_id: platformId,
                        content_type: filters.contentType,
                        metrics_type: 'channels',
                    }"
                    no-results-text="All matches selected"
                    @selection-change="(item) => onGameChange(item)"
                />
            </div>

            <div class="mt-4 flex">
                <div class="flex">
                    <label class="mb-auto mt-auto text-sm font-medium">Between</label>

                    <div class="relative ml-8 mr-4 pl-1">
                        <flat-pickr
                            v-model="filters.startAt"
                            class="form-input w-full pl-9 font-medium text-slate-500 hover:text-slate-600 focus:border-slate-300"
                            :config="pickerConfig"
                        />
                        <div class="pointer-events-none absolute inset-0 right-auto flex items-center">
                            <svg class="ml-3 h-4 w-4 fill-current text-slate-500" viewBox="0 0 16 16">
                                <path
                                    d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z"
                                />
                            </svg>
                        </div>
                    </div>
                    <span class="mb-auto mt-auto align-middle text-sm font-medium">and</span>
                    <div class="relative ml-4">
                        <flat-pickr
                            v-model="filters.endAt"
                            class="form-input w-full pl-9 font-medium text-slate-500 hover:text-slate-600 focus:border-slate-300"
                            :config="pickerConfig"
                        />
                        <div class="pointer-events-none absolute inset-0 right-auto flex items-center">
                            <svg class="ml-3 h-4 w-4 fill-current text-slate-500" viewBox="0 0 16 16">
                                <path
                                    d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex grow items-end justify-end">
            <button
                :disabled="!canGenerateReport"
                class="btn text-white enabled:bg-indigo-500 enabled:hover:bg-indigo-600 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400"
                @click="onGenerateReport(false)"
            >
                <span>Create Report</span>
                <Tooltip v-if="!canGenerateReport" class="my-auto mr-2 box-content">
                    <template #trigger>
                        <font-awesome-icon
                            class="ml-2 h-4 w-4 text-slate-400 transition duration-200 hover:text-slate-500"
                            :icon="['fas', 'circle-info']"
                        />
                    </template>
                    <template #default>
                        <div class="font-bold">
                            <div class="space-y-2">
                                <p>To generate a report, please select a game and a time range up to 35 days.</p>
                            </div>
                        </div>
                    </template>
                </Tooltip>
            </button>
        </div>
    </div>

    <div v-if="!fixedDayRange && !state.hasGenerated"></div>

    <div v-else class="mt-4">
        <DiscoverTable
            :paging="paging"
            :creators="creators"
            :is-loading="!state.isLoaded"
            :is-busy="!state.isLoaded"
            :columns="platformColumns"
            :enabled-columns="table.enabledColumns"
            :platform-id="platformId"
            :is-selectable="true"
            id-prop="id"
            superview-path="partialview"
            :list-id="list?.id"
            @enabled-column-change="onEnabledColumnChange"
            @filter-change="onFilterChange"
            @paging-change="onPagingChange"
            @export-creators="onExportCreators"
            @remove-from-list="
                (creatorsToRemove) => {
                    $emit('remove-from-list', creatorsToRemove)
                }
            "
        >
            <template #creator="{ item }">
                <div class="relative flex w-full items-center justify-between">
                    <router-link
                        :to="{
                            path: $route.path,
                            query: {
                                ...$route.query,
                                modal: 'creator',
                                platform_id: platformId,
                                platform_user_id: item.partialview._id,
                            },
                        }"
                        class="align-center flex"
                    >
                        <NamePlate
                            class="my-auto"
                            :logo="item.partialview.normalized.avatar_url"
                            :name="item.partialview.normalized.name"
                            :display-name="item.partialview.normalized.display_name"
                            :platform-id="platformId"
                            :platform-user-id="item.partialview._id"
                            :is-drawer-enabled="false"
                        />
                        <div
                            class="absolute right-0 top-0 hidden h-full cursor-pointer rounded bg-slate-100 px-2 text-xs font-semibold transition duration-200 group-hover:flex hover:bg-slate-200"
                        >
                            <span class="my-auto flex uppercase">
                                <font-awesome-icon :icon="['fas', 'rectangle-list']" class="my-auto mr-1 h-4 w-4" />
                                <span class="my-auto leading-none">Open</span>
                            </span>
                        </div>
                    </router-link>
                </div>
            </template>

            <template #affinity="{ item, field, column }">
                <AffinityPlate
                    :affinity-percent="field"
                    :class="{
                        'opacity-25': column.is_disabled,
                    }"
                    :metadata="metadata"
                    :affinity="item.affinity"
                    :discover-entry="item"
                />
            </template>

            <template #language="{ field }">
                <Tooltip v-if="field" class="inline-block">
                    <template #trigger>
                        <div class="inline-block cursor-help rounded bg-slate-100 px-2 py-1 text-xs font-semibold">
                            <span class="uppercase">{{ field }}</span>
                        </div>
                    </template>
                    <template #default>
                        <div class="whitespace-nowrap text-xs">{{ getLanguageFromCode(field) }}</div>
                    </template>
                </Tooltip>
            </template>

            <template #country="{ field }">
                <Tooltip v-if="field" class="inline-block">
                    <template #trigger>
                        <div class="inline-block cursor-help rounded bg-slate-100 px-2 py-1 text-xs font-semibold">
                            <span class="uppercase">{{ field }}</span>
                        </div>
                    </template>
                    <template #default>
                        <div class="whitespace-nowrap text-xs">{{ getCountryFromCode(field) }}</div>
                    </template>
                </Tooltip>
            </template>

            <template #email="{ field }">
                <div
                    v-if="field"
                    class="group relative flex cursor-pointer items-center rounded bg-slate-100 px-2 py-1.5 text-xs font-medium hover:bg-slate-200"
                    @click="copyToClipboard($event, field)"
                >
                    <span class="leading-none text-slate-500 group-hover:text-slate-700">{{ field }}</span>
                    <font-awesome-icon
                        :icon="['fas', 'copy']"
                        class="mb-0.5 ml-1 h-3 w-3 text-slate-500 group-hover:text-slate-700"
                    />
                    <span
                        class="overlay-message absolute inset-0 flex items-center justify-center rounded bg-black bg-opacity-80 text-white opacity-0 transition-opacity duration-300"
                    >
                        <span class="pointer-events-none">Copied to clipboard!</span>
                    </span>
                </div>
            </template>

            <template #platform-link="{ field }">
                <TableCellPlatformLink
                    v-if="field"
                    :platform-id="field?.platform_id"
                    :platform-user-id="field?.platform_user_id"
                    :platform-user-name="field?.platform_user_name"
                    :link="field?.link"
                />
            </template>
        </DiscoverTable>
    </div>
</template>

<script>
import _ from 'lodash'
import voca from 'voca'
import { mapGetters } from 'vuex'
import { nextTick } from 'vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import { json2csvAsync } from 'json-2-csv'
import CodeSelect from '@/components/CodeSelect.vue'
import reportsAPI from '@/utils/api/api.reports'
import NamePlate from '@/components/plates/NamePlate.vue'
import Tooltip from '@/components/Tooltip.vue'

import DiscoverTable from '@/partials/tables/DiscoverTable.vue'
import TableCellPlatformLink from '@/partials/tables/creator-lists/TableCellPlatformLink.vue'
import AffinityPlate from '@/components/plates/AffinityPlate.vue'

export default {
    name: 'ListReport',

    components: {
        CodeSelect,
        flatPickr,
        NamePlate,
        Tooltip,
        DiscoverTable,
        AffinityPlate,
        TableCellPlatformLink,
    },

    props: {
        list: {
            type: Object,
        },
        platformId: {
            type: String,
        },
        fixedDayRange: {
            type: Boolean,
        },
        searchText: {
            type: String,
        },
    },

    emits: ['remove-from-list'],

    data() {
        return {
            creators: [],
            state: {
                hasGenerated: false,
                isLoaded: true,
                table: {
                    columns: [],
                    enabledColumns: [],
                },
            },
            paging: {
                pages: 1,
                perPage: 25,
                currentPage: 1,
                totalPages: 1,
                sort: [
                    {
                        field: 'partialview.normalized.name',
                        direction: 'asc',
                        source: 'default',
                    },
                ],
            },
            filters: {
                game: null,
                startAt: moment().add(-8, 'days').add(-moment().minute(), 'minutes').toDate(),
                endAt: moment().add(-moment().minute(), 'minutes').toDate(),
                contentType: '',
                dayRange: 30,
            },
            pickerConfig: {
                enableTime: true,
                dateFormat: 'Z',
                altFormat: 'F j, Y h:i K',
                altInput: true,
                maxDate: moment().add(-moment().minute(), 'minutes').toDate(),
            },
            table: {
                enabledColumns: [],
                defaultEnabledColumns: {
                    twitch: [
                        'partialview.normalized.name',
                        'partialview.normalized.followers',
                        'summary.avg_viewers',
                        'partialview.channel.followers',
                        'summary.sum_duration_seconds',
                        'summary.chapters_count',
                        'language',
                        'country',
                    ],
                    youtube: [
                        'partialview.normalized.name',
                        'partialview.normalized.followers',
                        'summary.avg_views',
                        'summary.sum_views',
                        'summary.sum_videos',
                        'language',
                        'country',
                    ],
                    tiktok: [
                        'partialview.normalized.name',
                        'partialview.normalized.followers',
                        'summary.avg_views',
                        'summary.sum_videos',
                        'language',
                        'country',
                    ],
                    twitter: [
                        'partialview.normalized.name',
                        'partialview.normalized.followers',
                        'summary.avg_views',
                        'summary.sum_posts',
                        'language',
                        'country',
                    ],
                    instagram: [
                        'partialview.normalized.name',
                        'partialview.normalized.followers',
                        'summary.avg_views',
                        'summary.sum_posts',
                        'language',
                        'country',
                    ],
                },
                columns: [
                    {
                        id: 'partialview.normalized.name',
                        key: 'partialview',
                        header: {
                            label: 'Creator',
                            is_sortable: true,
                            is_hideable: false,
                            class: '',
                        },
                        row: {
                            class: '',
                            slot: 'creator',
                            slotTransformer: (item) => ({
                                // eslint-disable-next-line no-underscore-dangle
                                id: item.partialview._id,
                                name: item.partialview.normalized.name,
                                display_name: item.partialview.normalized.display_name,
                                logo: `https://streamforge-etl-media.nyc3.cdn.digitaloceanspaces.com/${this.platformId}/avatars/small/${item.partialview._id}`,
                            }),
                        },
                        is_sticky: true,
                        is_exportable: true,
                        exportTransformer: (item) => {
                            return {
                                ID: item['data.partialview._id'],
                                Name: item['data.partialview.normalized.name'],
                            }
                        },
                        enabled: () => {
                            return ['twitch', 'youtube', 'tiktok', 'twitter', 'instagram'].includes(this.platformId)
                        },
                    },
                    {
                        id: 'summary.avg_viewers',
                        key: `summary.avg_viewers`,
                        header: {
                            label: `Avg Viewers`,
                            sub_label: ``,
                            is_sortable: true,
                            tooltip: {
                                title: `Average Concurrent Viewers`,
                                sub_title: ``,
                                content: [
                                    {
                                        text: `The average number of viewers you would expect to see in this creator's stream at any point within the aggregation window.`,
                                    },
                                ],
                            },
                        },
                        row: {
                            class: '',
                            formatter: ({ field }) => {
                                if (field) {
                                    return this.$filters.formatWholeNumberWithUnit(field)
                                }
                                return '-'
                            },
                        },
                        is_exportable: true,
                        exportTransformer: (item) => {
                            return {
                                'Avg Viewers': item['data.summary.avg_viewers'] ? item['data.summary.avg_viewers'] : 0,
                            }
                        },
                        enabled: () => {
                            return ['twitch'].includes(this.platformId)
                        },
                    },
                    {
                        id: 'summary.peak_viewers',
                        key: `summary.peak_viewers.value`,
                        header: {
                            label: `Peak Viewers`,
                            sub_label: ``,
                            is_sortable: true,
                            tooltip: {
                                title: `Peak Concurrent Viewers`,
                                sub_title: ``,
                                content: [
                                    {
                                        text: `The maximum number of viewers that were in this creator's stream at any point within the aggregation window.`,
                                    },
                                ],
                            },
                        },
                        row: {
                            class: '',
                            formatter: ({ field }) => {
                                if (field) {
                                    return this.$filters.formatWholeNumberWithUnit(field)
                                }
                                return '-'
                            },
                        },
                        is_exportable: true,
                        exportTransformer: (item) => {
                            return {
                                'Peak Viewers': item['data.summary.peak_viewers.value']
                                    ? item['data.summary.peak_viewers.value']
                                    : 0,
                            }
                        },
                        enabled: () => {
                            return ['twitch'].includes(this.platformId)
                        },
                    },
                    {
                        id: 'summary.avg_chatters',
                        key: `summary.avg_chatters`,
                        header: {
                            label: `Avg Chatters`,
                            sub_label: ``,
                            is_sortable: true,
                            tooltip: {
                                title: `Average Concurrent Chatters`,
                                sub_title: ``,
                                content: [
                                    {
                                        text: `The average number of chatters you would expect to see in this creator's stream at any point within the aggregation window.`,
                                    },
                                ],
                            },
                        },
                        row: {
                            class: '',
                            formatter: ({ field }) => {
                                if (field) {
                                    return this.$filters.formatWholeNumberWithUnit(field)
                                }
                                return '-'
                            },
                        },
                        is_exportable: true,
                        exportTransformer: (item) => {
                            return {
                                'AVG Chatters': item['data.summary.avg_chatters']
                                    ? item['data.summary.avg_chatters']
                                    : 0,
                            }
                        },
                        enabled: () => {
                            return ['twitch'].includes(this.platformId)
                        },
                    },
                    {
                        id: 'summary.streams_count',
                        key: `summary.streams_count`,
                        header: {
                            label: `Stream Count`,
                            sub_label: ``,
                            is_sortable: true,
                            tooltip: {
                                title: `Stream count`,
                                sub_title: ``,
                                content: [
                                    {
                                        text: `The average number of streams during the aggregation window.`,
                                    },
                                ],
                            },
                        },
                        row: {
                            class: '',
                            formatter: ({ field }) => {
                                if (field) {
                                    return this.$filters.formatWholeNumberWithUnit(field)
                                }
                                return '-'
                            },
                        },
                        is_exportable: true,
                        enabled: () => {
                            return ['twitch'].includes(this.platformId)
                        },
                        exportTransformer: (item) => {
                            return {
                                'Streams Count': item['data.summary.streams_count']
                                    ? this.$filters.formatWholeNumberWithUnit(item['data.summary.streams_count'])
                                    : 0,
                            }
                        },
                    },
                    {
                        id: 'summary.sum_duration_seconds',
                        key: `summary.sum_duration_seconds`,
                        header: {
                            label: `Hours streamed`,
                            sub_label: ``,
                            is_sortable: true,
                            tooltip: {
                                title: `Hours streamed`,
                                sub_title: ``,
                                content: [
                                    {
                                        text: `The total number of hours streamed during the aggregation window.`,
                                    },
                                ],
                            },
                        },
                        row: {
                            class: '',
                            formatter: ({ field }) => {
                                if (field) {
                                    return this.$filters.formatWholeNumberWithUnit(field / 60 / 60)
                                }
                                return '-'
                            },
                        },
                        is_exportable: true,
                        enabled: () => {
                            return ['twitch'].includes(this.platformId)
                        },
                        exportTransformer: (item) => {
                            return {
                                'Hours Streamed': item['data.summary.sum_duration_seconds']
                                    ? this.$filters.formatWholeNumberWithUnit(
                                          item['data.summary.sum_duration_seconds'] / 60 / 60,
                                      )
                                    : 0,
                            }
                        },
                    },
                    {
                        id: 'partialview.normalized.followers',
                        key: `partialview.normalized.followers`,
                        header: {
                            label: `Followers`,
                            sub_label: ``,
                            is_sortable: true,
                            tooltip: {
                                title: `Followers`,
                                sub_title: ``,
                                content: [
                                    {
                                        text: `The channel's current number of followers.`,
                                    },
                                ],
                            },
                        },
                        row: {
                            class: '',
                            formatter: ({ field }) => {
                                if (field) {
                                    return this.$filters.formatWholeNumberWithUnit(field)
                                }
                                return '-'
                            },
                        },
                        is_exportable: true,
                        enabled: () => {
                            return true
                            // return ['twitch', 'tiktok', 'twitter', 'instagram'].includes(this.platformId)
                        },
                        exportTransformer: (item) => {
                            return {
                                Followers: item['data.partialview.normalized.followers']
                                    ? item['data.partialview.normalized.followers']
                                    : 0,
                            }
                        },
                    },
                    {
                        id: 'summary.sum_videos',
                        key: `summary.sum_videos`,
                        header: {
                            label: `Videos`,
                            sub_label: ``,
                            is_sortable: true,
                            tooltip: {
                                title: `Videos`,
                                sub_title: ``,
                                content: [
                                    {
                                        text: `The number of videos released during the period.`,
                                    },
                                ],
                            },
                        },
                        row: {
                            class: '',
                            formatter: ({ field }) => {
                                if (field) {
                                    return this.$filters.formatWholeNumberWithUnit(field)
                                }
                                return '-'
                            },
                        },
                        is_exportable: true,
                        enabled: () => {
                            return ['youtube', 'tiktok'].includes(this.platformId)
                        },
                        exportTransformer: (item) => {
                            return {
                                'Video Count': item['data.summary.sum_videos'] ? item['data.summary.sum_videos'] : 0,
                            }
                        },
                    },
                    {
                        id: 'summary.avg_views',
                        key: `summary.avg_views`,
                        header: {
                            label: `AVG Views`,
                            sub_label: ``,
                            is_sortable: true,
                            tooltip: {
                                title: `Average views`,
                                sub_title: ``,
                                content: [
                                    {
                                        text: `The average number of views per video during the period.`,
                                    },
                                ],
                            },
                        },
                        row: {
                            class: '',
                            formatter: ({ field }) => {
                                if (field) {
                                    return this.$filters.formatWholeNumberWithUnit(field)
                                }
                                return '-'
                            },
                        },
                        is_exportable: true,
                        enabled: () => {
                            return ['youtube', 'tiktok', 'twitter', 'instagram'].includes(this.platformId)
                        },
                        exportTransformer: (item) => {
                            return {
                                'Avg Views': item['data.summary.avg_views']
                                    ? parseInt(item['data.summary.avg_views'], 10)
                                    : 0,
                            }
                        },
                    },
                    {
                        id: 'summary.sum_views',
                        key: `summary.sum_views`,
                        header: {
                            label: `Total Views`,
                            sub_label: ``,
                            is_sortable: true,
                            tooltip: {
                                title: `Total views`,
                                sub_title: ``,
                                content: [
                                    {
                                        text: `The total number of views for videos during the period.`,
                                    },
                                ],
                            },
                        },
                        row: {
                            class: '',
                            formatter: ({ field }) => {
                                if (field) {
                                    return this.$filters.formatWholeNumberWithUnit(field)
                                }
                                return '-'
                            },
                        },
                        is_exportable: true,
                        enabled: () => {
                            return ['youtube', 'tiktok', 'twitter', 'instagram'].includes(this.platformId)
                        },
                        exportTransformer: (item) => {
                            return {
                                'Total Views': item['data.summary.sum_views'] ? item['data.summary.sum_views'] : 0,
                            }
                        },
                    },
                    {
                        id: 'summary.peak_views',
                        key: `summary.peak_views`,
                        header: {
                            label: `Peak Views`,
                            sub_label: ``,
                            is_sortable: true,
                            tooltip: {
                                title: `Peak views`,
                                sub_title: ``,
                                content: [
                                    {
                                        text: `The peak number of views for videos during the period.`,
                                    },
                                ],
                            },
                        },
                        row: {
                            class: '',
                            formatter: ({ field }) => {
                                if (field) {
                                    return this.$filters.formatWholeNumberWithUnit(field)
                                }
                                return '-'
                            },
                        },
                        is_exportable: true,
                        enabled: () => {
                            return ['youtube', 'tiktok', 'twitter', 'instagram'].includes(this.platformId)
                        },
                        exportTransformer: (item) => {
                            return {
                                'Peak Views': item['data.summary.peak_views'] ? item['data.summary.peak_views'] : 0,
                            }
                        },
                    },
                    {
                        id: 'language',
                        key: 'metadata.language',
                        header: {
                            label: 'Language',
                            is_sortable: false,
                        },
                        row: {
                            class: '',
                            slot: 'language',
                        },
                        is_exportable: true,
                        enabled: () => {
                            return ['twitch', 'youtube', 'tiktok', 'twitter', 'instagram'].includes(this.platformId)
                        },
                        exportTransformer: (item) => {
                            return {
                                Language: item['data.metadata.language'] ? item['data.metadata.language'] : '',
                            }
                        },
                    },
                    {
                        id: 'country',
                        key: 'metadata.country',
                        header: {
                            label: 'Country',
                            is_sortable: false,
                        },
                        row: {
                            class: '',
                            slot: 'country',
                        },
                        is_exportable: true,
                        enabled: () => {
                            return ['twitch', 'youtube', 'tiktok', 'twitter', 'instagram'].includes(this.platformId)
                        },
                        exportTransformer: (item) => {
                            return {
                                Country: item['data.metadata.country'] ? item['data.metadata.country'] : '',
                            }
                        },
                    },
                    {
                        id: 'email',
                        key: 'metadata.email',
                        header: {
                            label: 'Email',
                            is_sortable: false,
                        },
                        row: {
                            class: '',
                            slot: 'email',
                        },
                        is_exportable: true,
                        enabled: () => true,
                        exportTransformer: (item) => {
                            return {
                                Email: item['data.metadata.email'] ? item['data.metadata.email'] : '',
                            }
                        },
                    },
                    {
                        id: 'lastmessage',
                        key: 'messages.last_messaged_at',
                        header: {
                            label: 'Last message',
                            is_sortable: false,
                        },
                        row: {
                            class: '',
                            formatter: ({ field }) => {
                                if (field) {
                                    return this.$filters.formatDateAndTime(field)
                                }
                                return '-'
                            },
                        },
                        is_exportable: true,
                        enabled: () => {
                            return true
                        },
                        exportTransformer: (item) => {
                            return {
                                LastMessage: item['data.messages.last_messaged_at']
                                    ? item['data.messages.last_messaged_at']
                                    : '',
                            }
                        },
                    },
                    ..._.map(['twitch', 'youtube', 'tiktok', 'twitter', 'instagram'], (platformId) => {
                        return {
                            id: `link_${platformId}`,
                            key: `metadata.socials.${platformId}`,
                            header: {
                                label: `${voca.titleCase(platformId)} Link`,
                                is_sortable: false,
                            },
                            row: {
                                class: '',
                                slot: 'platform-link',
                            },
                            is_exportable: true,
                            enabled: () => {
                                return true
                            },
                            exportTransformer: (item) => {
                                return {
                                    [`${platformId} Link`]: item[`data.metadata.socials.${platformId}.link`]
                                        ? item[`data.metadata.socials.${platformId}.link`]
                                        : '',
                                }
                            },
                        }
                    }),
                ],
            },
            platformContentTypes: {
                twitch: [{ id: 'stream', label: 'Stream', isDefault: true }],
                youtube: [
                    { id: 'video', label: 'Video', isDefault: true },
                    { id: 'short', label: 'Short' },
                    { id: 'stream', label: 'Stream' },
                ],
                tiktok: [{ id: 'video', label: 'Short', isDefault: true }],
                twitter: [{ id: 'post', label: 'Post', isDefault: true }],
                instagram: [{ id: 'post', label: 'Post', isDefault: true }],
            },
            metricsWindows: [
                { dayRange: 30, title: 'day_30' },
                { dayRange: 90, title: 'day_90' },
                { dayRange: 365, title: 'day_365' },
            ],
        }
    },

    computed: {
        ...mapGetters(['getFromDictionary', 'isSfStaff', 'getLanguageFromCode', 'getCountryFromCode', 'preferences']),
        canGenerateReport() {
            return (
                (this.filters.game || this.filters.tag) &&
                this.filters.startAt &&
                this.filters.endAt &&
                Math.abs(moment(this.filters.startAt).diff(moment(this.filters.endAt), 'days')) <= 36
            )
        },

        platformColumns() {
            return _.filter(this.table.columns, (column) => {
                return column.enabled() === true
            })
        },
    },

    watch: {
        list() {
            this.reset()
        },
        platformId() {
            this.reset()
        },
        fixedDayRange() {
            this.reset()
        },
        searchText() {
            this.debounceGenerate()
        },
        'filters.contentType': function () {
            this.paging.currentPage = 1
            this.debounceGenerate()
        },
    },

    created() {
        this.setEnabledColumnDefaults()
        this.reset()
    },

    methods: {
        async copyToClipboard(event, textToCopy) {
            try {
                await navigator.clipboard.writeText(textToCopy)

                const overlayMessage = event.target

                if (overlayMessage) {
                    overlayMessage.classList.add('opacity-100')

                    setTimeout(() => {
                        overlayMessage.classList.remove('opacity-100')
                    }, 2500)
                } else {
                    console.error('Overlay message element not found.')
                }
            } catch (err) {
                console.error('Failed to copy text: ', err)
            }
        },

        reset() {
            this.paging.currentPage = 1
            this.creators = []
            this.state.hasGenerated = false
            this.state.isLoaded = false
            this.filters.contentType = _.find(this.platformContentTypes[this.platformId], (contentType) => {
                return contentType.isDefault
            })?.id
            this.setEnabledColumnDefaults()
            this.debounceGenerate()
        },

        debounceGenerate: _.debounce(function () {
            nextTick(() => {
                if (this.fixedDayRange) {
                    this.onGenerateReport(false)
                }
            })
        }, 250),

        onGameChange(games) {
            this.filters.game = _.first(games)
        },

        onTagChange(tags) {
            this.filters.tag = _.first(tags)
        },

        onChangeSort(sortCol) {
            if (sortCol === this.paging.sort.sortBy) {
                if (this.paging.sort.sortDir === 'asc') {
                    this.paging.sort.sortDir = ''
                    this.paging.sort.sortBy = ''
                } else {
                    this.paging.sort.sortDir = 'asc'
                }
            } else {
                this.paging.sort.sortBy = sortCol
                this.paging.sort.sortDir = 'desc'
            }
        },

        async onGenerateReport(isExport) {
            if (!this.list || !this.list.id) {
                return null
            }

            if (!isExport) {
                this.state.hasGenerated = true
                this.state.isLoaded = false
            }

            const options = {}
            const filters = []

            options.contentType = this.filters.contentType

            if (this.fixedDayRange) {
                options.dayRange = this.filters.dayRange
            } else {
                options.tags = this.filters?.tag?.id ? [this.filters?.tag?.id] : []
                options.gameId = this.filters?.game?.id
                options.gameName = this.filters?.game?.name
                options.startAt = this.filters.startAt
                options.endAt = this.filters.endAt
            }

            if (this.searchText) {
                filters.push({ id: 'name', value: this.searchText })
            }

            this.$store.dispatch('CANCEL_PENDING_REQUESTS_BY_REGEX', '^/lists/[^/]+/report$')

            return reportsAPI
                .getListReport(this.$axios, this.list.id, this.platformId, this.paging, options, filters, isExport)
                .then((response) => {
                    if (isExport) {
                        this.generateCSV(response.value)
                    } else {
                        if (!response.success) return

                        const report = response.value

                        if (this.list.id !== report?.listId) {
                            return
                        }

                        this.creators = []

                        if (report.items) {
                            this.creators = _.map(report.items, (reportItem) => {
                                return reportItem.data
                            })
                        }
                        this.paging.totalRecords = report.pager.totalRecords
                        this.paging.totalPages = report.pager.totalPages
                        this.paging.currentPage = report.pager.currentPage

                        this.state.isLoaded = true
                    }
                })
        },

        async onFilterChange(updatedSort) {
            _.assign(this.paging.sort, updatedSort)
            await this.onGenerateReport(false)
        },

        async onPagingChange(updatedPaging) {
            _.assign(this.paging, updatedPaging)
            await this.onGenerateReport(false)
        },

        onEnabledColumnChange(newEnabledColumns) {
            this.table.enabledColumns = newEnabledColumns
            this.$store.commit('preferences:set', {
                key: `tables.listreport.${this.platformId}.columns`,
                value: this.table.enabledColumns,
            })
        },

        setEnabledColumnDefaults() {
            if (
                this.preferences?.tables?.listreport &&
                this.preferences?.tables?.listreport[this.platformId]?.columns
            ) {
                this.table.enabledColumns = this.preferences.tables.listreport[this.platformId].columns
            } else {
                this.table.enabledColumns = this.table.defaultEnabledColumns[this.platformId]
            }
        },

        onExportCreators() {
            this.$notify({
                type: 'info',
                text: 'Exporting list of creators... Please wait a moment.',
                duration: 10000,
                speed: 1000,
            })
            this.onGenerateReport(true)
        },

        async generateCSV(data) {
            try {
                const link = document.createElement('a')

                const mappedFields = _.map(data.items, (creator) => {
                    const result = {}

                    _.forEach(this.platformColumns, (column) => {
                        if (!column.is_exportable) return

                        if (_.has(column, 'exportTransformer')) {
                            _.assign(result, column.exportTransformer(creator))
                        } else {
                            const value = _.get(creator, column.key)
                            result[column.header.label] = value !== undefined && value !== null ? value : ''
                        }
                    })

                    return result
                })

                const csvResults = await json2csvAsync(mappedFields)

                link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(csvResults)}`)
                link.setAttribute('download', 'Creators.csv')
                link.style.visibility = 'hidden'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } catch (error) {
                console.error('Error exporting creators', error)
                this.$notify.error({
                    title: 'Error exporting creators',
                    text: 'There was an error exporting the list of creators. Please try again.',
                })
            }
        },
    },
}
</script>
