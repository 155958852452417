<template>
    <!-- Modal backdrop -->
    <div
        v-show="modals.searchBar.isOpen"
        class="fixed inset-0 z-40 bg-slate-900 bg-opacity-60 transition-opacity"
        aria-hidden="true"
        @click="closeModal"
    />

    <div
        class="relative flex lg:w-[400px] xl:w-[500px]"
        :class="{ 'z-50 shadow-md': modals.searchBar.isOpen === true }"
    >
        <div class="relative flex grow items-center">
            <label class="sr-only">Search</label>

            <input
                ref="searchInput"
                v-model="search.text"
                class="w-full appearance-none rounded border border-slate-300 py-2 pl-10 pr-10 text-sm placeholder-slate-400 outline-none placeholder:text-sm focus:border-slate-300 focus:ring-0 focus:ring-transparent"
                type="search"
                placeholder="Search a creator by name..."
                @keyup="debounceSearch"
                @click="openModal"
            />

            <font-awesome-icon
                v-if="search.text && search.text.length"
                :icon="['fas', 'times']"
                class="absolute right-0 top-0 mr-4 mt-3 h-4 w-4 cursor-pointer hover:text-rose-500"
                @click.stop="resetState"
            />

            <button class="group absolute inset-0 right-auto" disabled aria-label="Search">
                <svg
                    class="ml-4 mr-2 h-4 w-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
                    />
                    <path
                        d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
                    />
                </svg>
            </button>
        </div>

        <div
            v-show="modals.searchBar.isOpen"
            role="dialog"
            aria-modal="true"
            class="absolute left-0 top-full z-50 mt-2.5 flex max-h-[350px] w-full items-start justify-center shadow-lg will-change-auto"
        >
            <div ref="modalContent" class="no-scrollbar relative w-full rounded">
                <div class="h-inherit flex w-full flex-col rounded bg-white">
                    <!-- Recent Searches -->
                    <div v-if="search.text === '' && recentSearches.length">
                        <div class="flex justify-between border-b px-4 py-1.5">
                            <div class="flex items-center text-sm font-semibold text-slate-600">
                                Recent Searches ({{ recentSearches?.length }})
                            </div>
                            <div v-if="recentSearches.length > 0" class="flex items-center">
                                <div
                                    class="flex cursor-pointer rounded bg-slate-100 px-2 py-2 text-xs font-semibold text-slate-500 transition duration-200 hover:bg-rose-100 hover:text-rose-500"
                                    @click.stop="onCloseClick"
                                >
                                    <font-awesome-icon :icon="['fa', 'trash']" class="my-auto h-3 w-3" />
                                    <!-- <span class="ml-1 leading-none mt-[2px]">Clear</span> -->
                                </div>
                            </div>
                        </div>
                        <div
                            class="relative max-h-[350px] w-full overflow-auto overscroll-auto rounded md:scrollbar md:scrollbar-track-transparent md:scrollbar-thumb-slate-300"
                        >
                            <div
                                v-if="recentSearches.length > 0"
                                class="flex flex-col divide-y divide-slate-200 rounded border-b border-slate-200 bg-white"
                            >
                                <div
                                    v-for="recentSearch in recentSearches"
                                    :key="recentSearch"
                                    class="group flex cursor-pointer px-4 py-2.5 text-sm transition duration-200 hover:bg-slate-50"
                                    @click="
                                        () => {
                                            search.text = recentSearch
                                            debounceSearch()
                                        }
                                    "
                                >
                                    <div class="my-auto flex w-full items-center justify-between">
                                        <div class="flex items-center">
                                            <div class="relative mr-2">
                                                <svg
                                                    class="h-4 w-4 shrink-0 fill-current text-slate-400"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path
                                                        d="M15.707 14.293v.001a1 1 0 01-1.414 1.414L11.185 12.6A6.935 6.935 0 017 14a7.016 7.016 0 01-5.173-2.308l-1.537 1.3L0 8l4.873 1.12-1.521 1.285a4.971 4.971 0 008.59-2.835l1.979.454a6.971 6.971 0 01-1.321 3.157l3.107 3.112zM14 6L9.127 4.88l1.521-1.28a4.971 4.971 0 00-8.59 2.83L.084 5.976a6.977 6.977 0 0112.089-3.668l1.537-1.3L14 6z"
                                                    />
                                                </svg>
                                            </div>
                                            <p class="flex font-medium">{{ recentSearch }}</p>
                                        </div>
                                        <div
                                            class="flex items-center rounded bg-slate-100 p-1.5 text-slate-400 transition duration-200 hover:bg-slate-200 hover:text-slate-600"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'times']"
                                                class="h-3 w-3 cursor-pointer"
                                                @click.stop="removeRecentSearch(recentSearch)"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-else
                                class="flex flex-col divide-y divide-slate-200 rounded bg-white px-4 py-2.5 text-sm"
                            >
                                <p>No recent searches</p>
                            </div>
                        </div>
                    </div>

                    <div v-if="search.text !== ''">
                        <!--Results  -->
                        <div
                            class="flex justify-between overflow-hidden rounded-tl rounded-tr border-b border-slate-200 text-slate-400"
                        >
                            <div class="flex">
                                <div
                                    class="flex cursor-pointer border-r border-slate-100 px-4 hover:bg-slate-100"
                                    :class="[search.platform === `all` ? 'bg-slate-200 hover:bg-slate-200' : '']"
                                    @click="onPlatformSelect('all')"
                                >
                                    <div class="my-auto text-sm font-semibold capitalize text-slate-600">
                                        All Platforms
                                    </div>
                                </div>
                                <div
                                    v-for="platform in supportedPlatforms"
                                    :key="platform"
                                    class="flex cursor-pointer border-r border-slate-100 px-4 hover:bg-slate-100"
                                    :class="[
                                        search.platform === `${platform}` ? 'bg-slate-200 hover:bg-slate-200' : '',
                                    ]"
                                    @click="onPlatformSelect(platform)"
                                >
                                    <div class="flex py-3">
                                        <font-awesome-icon
                                            class="m-auto h-4 w-4"
                                            :class="`text-${platform}`"
                                            :icon="['fab', `${platform}`]"
                                        />
                                    </div>
                                    <!-- <div class="my-auto ml-2 text-sm font-semibold capitalize text-slate-600">
                                        {{ platform }}
                                    </div> -->
                                </div>
                            </div>

                            <!-- <div class="my-auto px-4 py-5 text-xs font-semibold uppercase tracking-wider">
                                Creators <span v-if="results?.creators?.length">({{ results?.creators?.length }})</span>
                            </div>
                            <div class="my-auto px-2">
                                <div
                                    class="flex space-x-1 rounded bg-slate-200 px-1 py-1"
                                    aria-orientation="horizontal"
                                >
                                    <button
                                        class="btn group flex rounded transition duration-200 hover:bg-slate-100"
                                        :class="[search.platform === 'all' ? 'bg-slate-50' : 'bg-slate-200']"
                                        @click="onPlatformSelect('all')"
                                    >
                                        <span>All</span>
                                    </button>

                                    <button
                                        v-for="platform in supportedPlatforms"
                                        :key="platform"
                                        class="btn group flex w-full transition duration-200 hover:bg-slate-100"
                                        :class="[search.platform === `${platform}` ? 'bg-slate-50' : 'bg-slate-200']"
                                        @click="onPlatformSelect(platform)"
                                    >
                                        <font-awesome-icon
                                            class="h-4 w-4"
                                            :class="`text-${platform}`"
                                            :icon="['fab', `${platform}`]"
                                        />
                                    </button>
                                </div>
                            </div> -->
                        </div>

                        <div v-if="!results.isLoaded">
                            <SkeletonListSearch />
                        </div>

                        <div
                            v-if="results.isLoaded && results.creators && results.creators.length"
                            class="relative max-h-[350px] w-full overflow-auto rounded md:scrollbar md:scrollbar-track-transparent md:scrollbar-thumb-slate-300"
                        >
                            <div
                                class="flex flex-col divide-y divide-slate-200 rounded border-b border-slate-200 bg-white"
                            >
                                <div v-for="creator in results.creators" :key="creator.item.superview._id">
                                    <router-link
                                        :to="{
                                            path: $route.path,
                                            query: {
                                                ...query,
                                                modal: 'creator',
                                                platform_id: creator.item.superview.platform_id,
                                                platform_user_id: creator.item.superview._id,
                                            },
                                        }"
                                    >
                                        <div
                                            class="group flex h-[50px] cursor-pointer px-4 py-2 text-sm transition duration-200 hover:bg-slate-100"
                                        >
                                            <div class="grow">
                                                <div class="flex">
                                                    <div class="flex items-center">
                                                        <div class="relative mr-2 shrink-0 sm:mr-3">
                                                            <Avatar
                                                                class="bg-slate-700 text-slate-50"
                                                                :srcs="[creator.item.superview.normalized.avatar_url]"
                                                                :username="creator.item.superview.normalized.name"
                                                                :size="32"
                                                            />

                                                            <div
                                                                class="absolute z-10 flex rounded-full"
                                                                :class="
                                                                    ['twitch'].includes(
                                                                        creator.item.superview.platform_id,
                                                                    )
                                                                        ? '-bottom-2 -right-1.5'
                                                                        : '-bottom-1 -right-1'
                                                                "
                                                            >
                                                                <PlatformIcon
                                                                    :platform-id="creator.item.superview.platform_id"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="flex flex-col">
                                                            <span
                                                                v-html="
                                                                    highlightMatchingResults(
                                                                        creator.matches[0].value,
                                                                        creator.matches[0].indices,
                                                                    )
                                                                "
                                                            >
                                                            </span>
                                                            <span
                                                                v-if="creator.matches[1]"
                                                                class="-my-1 text-xs text-slate-500"
                                                                v-html="
                                                                    atSign +
                                                                    highlightMatchingResults(
                                                                        creator.matches[1].value,
                                                                        creator.matches[1].indices,
                                                                    )
                                                                "
                                                            >
                                                            </span>
                                                            <span v-else class="-my-1 text-xs text-slate-500">
                                                                @{{ creator.item.superview.normalized.name }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <span
                                                v-if="creator.item.superview.normalized.followers > 0"
                                                class="my-auto text-sm text-slate-600"
                                            >
                                                {{
                                                    $filters.formatWholeNumberWithUnit(
                                                        creator.item.superview.normalized.followers,
                                                    )
                                                }}
                                                followers</span
                                            >
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div
                            v-else-if="results.isLoaded && results.creators && results.creators.length === 0"
                            class="flex flex-col divide-y divide-slate-200 rounded bg-white px-4 py-2.5 text-sm"
                        >
                            <p>Couldn't find any creators named "{{ search.text }}"</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal dialog -->
    <ModalBlank
        :modal-open="modals.close.isOpen"
        fg-classes="flex items-center my-4 justify-center"
        bg-classes="z-50 bg-opacity-75"
        @close-modal="modals.close.isOpen = false"
    >
        <div class="p-5">
            <div class="flex space-x-4">
                <!-- Icon -->
                <div class="flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-indigo-100">
                    <svg class="h-4 w-4 shrink-0 fill-current text-indigo-500" viewBox="0 0 16 16">
                        <path
                            d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
                        />
                    </svg>
                </div>

                <!-- Content -->
                <div class="grow">
                    <!-- Modal header -->
                    <div class="mb-2">
                        <div class="text-lg font-semibold text-slate-800">Are you sure?</div>
                    </div>

                    <!-- Modal content -->
                    <div class="mb-10 text-sm">
                        <div class="space-y-2">
                            <p>This action will clear all recent searches.</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal footer -->
            <div class="flex flex-wrap justify-end space-x-2">
                <button
                    class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300"
                    @click.stop="modals.close.isOpen = false"
                >
                    Cancel
                </button>
                <button class="btn-sm bg-indigo-500 text-white hover:bg-indigo-600" @click.stop="onCloseModalConfirm">
                    Close &amp; clear
                </button>
            </div>
        </div>
    </ModalBlank>
</template>

<script>
import _ from 'lodash'
import { nextTick } from 'vue'
import { mapGetters } from 'vuex'
import Fuse from 'fuse.js'

import ModalBlank from '@/components/ModalBlank.vue'
import SkeletonListSearch from '@/partials/skeletons/SkeletonListSearch.vue'
import Avatar from '@/components/Avatar.vue'
import PlatformIcon from '@/components/PlatformIcon.vue'

export default {
    name: 'GlobalSearchBar',

    components: {
        ModalBlank,
        Avatar,
        SkeletonListSearch,
        PlatformIcon,
    },

    props: ['actionText', 'defaultPlatformId'],

    data() {
        return {
            supportedPlatforms: ['twitch', 'youtube', 'tiktok', 'twitter', 'instagram'],

            modals: {
                close: {
                    isOpen: false,
                },
                searchBar: {
                    isOpen: false,
                },
            },
            results: {
                creators: null,
                isLoaded: false,
            },
            search: {
                lastText: '',
                text: '',
                platform: 'all',
            },
            atSign: '@',
        }
    },

    computed: {
        ...mapGetters(['recentSearches']),

        isInputValid() {
            if (!this.search.text) return false
            if (typeof this.search.text !== 'string') return false

            const trimmedSearchText = this.search.text.trim()

            if (!trimmedSearchText) return false
            if (trimmedSearchText.length < 2) return false

            return true
        },

        query() {
            return this.$route.query
        },
    },

    methods: {
        openModal() {
            this.modals.searchBar.isOpen = true
        },

        closeModal() {
            this.modals.searchBar.isOpen = false
            this.resetState()
        },

        debounceSearch: _.debounce(function () {
            // Prevent execution if the text box hasn't changed
            if (this.search.lastText === this.search.text) return

            nextTick(() => this.searchCreators())
        }, 500),

        searchCreators() {
            this.search.lastText = this.search.text

            if (this.isInputValid) {
                this.getCreators(this.search.text.trim())
            } else {
                this.results.creators = null
                this.results.isLoaded = false
            }
        },

        addRecentSearch(searchQuery) {
            this.$store.commit('recentSearches:add', searchQuery.trim())
        },

        removeRecentSearch(searchQuery) {
            this.$store.commit('recentSearches:remove', searchQuery.trim())
        },

        clearRecentSearches() {
            this.$store.commit('recentSearches:clear')
        },

        resetState() {
            this.search.text = ''
            this.search.lastText = ''
            this.results.creators = []
            this.results.isLoaded = false
        },

        onCloseClick() {
            this.modals.close.isOpen = true
        },

        onCloseModalConfirm() {
            this.modals.close.isOpen = false
            this.clearRecentSearches()
            this.$emit('close-modal')
            this.resetState()
        },

        async getCreatorsByPlatform(trimmedSearchText, platform) {
            let creators = []
            const endpoint = `/discover/${platform}/name`
            const body = {
                name: trimmedSearchText,
                platform,
            }

            await this.$axios
                .post(endpoint, body)
                .then((response) => {
                    creators = _.map(response.data.payload.creators, (creator) => {
                        if (platform === 'instagram') {
                            creator.superview.normalized.avatar_url = `${
                                this.$axios.defaults.baseURL
                            }/avatars?url=${encodeURIComponent(creator.superview.normalized.avatar_url)}`
                        }

                        return creator
                    })
                })
                .catch((error) => {
                    this.$errorHandler(error, {
                        title: 'Creator Search',
                        message: 'Error while searching creators.',
                    })
                })

            return creators
        },

        async getCreators(trimmedSearchText) {
            this.results.creators = []
            this.results.isLoaded = false

            const platforms = this.search.platform === 'all' ? this.supportedPlatforms : [this.search.platform]

            const creatorsPromises = platforms.map((platform) =>
                this.getCreatorsByPlatform(trimmedSearchText, platform),
            )
            this.results.creators = (await Promise.all(creatorsPromises)).flat()

            if (this.results.creators.length !== 0) {
                this.addRecentSearch(trimmedSearchText)
            }

            this.filterResultsByPlatform()

            this.results.isLoaded = true
        },

        filterResultsByPlatform() {
            const fuseOptions = {
                keys: ['superview.normalized.display_name', 'superview.normalized.name'],
                findAllMatches: true,
                includeMatches: true,
                minMatchCharLength: 2,
                shouldSort: true,
            }

            const fuse = new Fuse(this.results.creators, fuseOptions)
            const fuseResults = fuse.search(this.search.text)

            this.results.creators = _.orderBy(fuseResults, ['item.superview.normalized.followers'], ['desc']).filter(
                (creator) => {
                    const isAllPlatforms = this.search.platform === 'all'
                    const hasEnoughFollowers = creator.item.superview.normalized.followers >= 100
                    const isMatchingPlatform = creator.item.superview.platform_id === this.search.platform

                    // We only want to filter out low followers if we have more than 10 results
                    if (this.search.platform !== 'instagram') {
                        return isAllPlatforms ? hasEnoughFollowers : hasEnoughFollowers && isMatchingPlatform
                    }

                    return isAllPlatforms ? true : isMatchingPlatform
                },
            )
        },

        onPlatformSelect(platform) {
            if (platform === this.search.platform) return

            this.results.isLoaded = false

            console.log(`CANCELLING REQUESTS`)
            this.$store.dispatch('CANCEL_PENDING_REQUESTS_BY_ENDPOINT', '/discover/twitch/name')
            this.$store.dispatch('CANCEL_PENDING_REQUESTS_BY_ENDPOINT', '/discover/youtube/name')
            this.$store.dispatch('CANCEL_PENDING_REQUESTS_BY_ENDPOINT', '/discover/tiktok/name')
            this.$store.dispatch('CANCEL_PENDING_REQUESTS_BY_ENDPOINT', '/discover/twitter/name')
            this.$store.dispatch('CANCEL_PENDING_REQUESTS_BY_ENDPOINT', '/discover/instagram/name')

            this.search.platform = platform
            this.searchCreators()
        },

        highlightMatchingResults(str, indices) {
            const modifiedStr = Array.from(str)
                .map((char, index) => {
                    const isInRange = indices.some(([start, end]) => index >= start && index <= end)
                    return isInRange ? `<span class="font-bold text-slate-600">${char}</span>` : char
                })
                .join('')

            return modifiedStr
        },
    },
}
</script>
