<template>
    <div class="relative flex min-h-full flex-col">
        <!-- Results -->
        <div v-if="results.isLoading || results.showResultsPage">
            <div class="relative flex flex-col">
                <!-- Modal content -->
                <div>
                    <div v-if="results.isLoading" class="mt-2.5">Skeleton</div>
                    <div
                        v-if="!results.isLoading"
                        class="overflow-auto rounded md:scrollbar md:scrollbar-thin md:scrollbar-track-transparent md:scrollbar-thumb-slate-300"
                    >
                        <div class="h-96 overflow-auto">
                            <ul>
                                <!-- added creators -->
                                <li
                                    v-for="creator in results.resultsType === 'list'
                                        ? results.lists.addedCreators
                                        : results.campaigns.addedCreators"
                                    :key="creator._id"
                                    class="flex items-center border-b px-5 py-2"
                                >
                                    <div class="flex-grow">
                                        <div class="flex">
                                            <div class="relative mr-2 shrink-0 sm:mr-3">
                                                <Avatar
                                                    class="bg-slate-700 text-slate-50"
                                                    :src="creator.superview.normalized.avatar_url"
                                                    :username="creator.superview.normalized.display_name"
                                                    :size="32"
                                                />

                                                <div
                                                    v-if="creator.superview.platform_id === 'twitch'"
                                                    class="absolute -bottom-2 -right-1.5 z-10 flex rounded-full"
                                                >
                                                    <svg
                                                        width="22"
                                                        height="22"
                                                        fill="none"
                                                        class="flex-shrink-0 fill-current"
                                                    >
                                                        <path
                                                            fill="#fff"
                                                            d="M7.95243 3.33337 4.3999 6.8859v11.3999h2.85715v5.2093l5.20935-5.2093h1.9048l5.4573-5.4573V3.33337H7.95243Z"
                                                        />
                                                        <path
                                                            fill="#9147FF"
                                                            d="M8.78086 5.33337 6.3999 7.71433v8.57147h2.85714v2.3809l2.38096-2.3809h1.9048L17.8285 12V5.33337H8.78086Z"
                                                        />
                                                        <path
                                                            fill="#fff"
                                                            d="m14.9711 13.4286 1.9048-1.9048V6.28574H9.25684v7.14286h1.90476v1.9048l1.9048-1.9048h1.9047Z"
                                                        />
                                                        <path
                                                            fill="#9147FF"
                                                            d="M12.1143 8.19037h.9523v2.85713h-.9523V8.19037Zm3.3333 0v2.85713h-.9524V8.19037h.9524Z"
                                                        />
                                                    </svg>
                                                </div>
                                                <div
                                                    v-else-if="creator.superview.platform_id === 'youtube'"
                                                    class="absolute -bottom-0.5 -right-1 z-10 flex rounded-full"
                                                >
                                                    <svg height="16" width="16" viewBox="0 0 490 490">
                                                        <g>
                                                            <g>
                                                                <path
                                                                    fill="#EA4640"
                                                                    d="M480,180v130c0,55.195-44.805,100-100,100H110c-55.195,0-100-44.805-100-100V180
                                            c0-55.199,44.805-100,100-100h270C435.195,80,480,124.801,480,180z"
                                                                />
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <polygon
                                                                        style="fill: #ffffff"
                                                                        points="320,245 200,295 200,195 "
                                                                    />
                                                                </g>
                                                                <g>
                                                                    <path
                                                                        fill="white"
                                                                        d="M380,70H110C49.346,70,0,119.346,0,180v130c0,60.654,49.346,110,110,110h270
                                                c60.654,0,110-49.346,110-110V180C490,119.346,440.654,70,380,70z M470,310c0,49.626-40.374,90-90,90H110
                                                c-49.626,0-90-40.374-90-90V180c0-49.626,40.374-90,90-90h270c49.626,0,90,40.374,90,90V310z"
                                                                    />
                                                                    <path
                                                                        fill="white"
                                                                        d="M323.846,235.769l-120-50c-3.085-1.286-6.611-0.945-9.393,0.911
                                                c-2.782,1.854-4.453,4.977-4.453,8.32v100c0,3.344,1.671,6.466,4.453,8.32c1.667,1.112,3.601,1.68,5.548,1.68
                                                c1.301,0,2.608-0.254,3.845-0.769l120-50c3.727-1.553,6.154-5.194,6.154-9.231S327.572,237.322,323.846,235.769z M210,280v-70
                                                l84,35L210,280z"
                                                                    />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div class="flex grow">
                                                <div class="my-auto flex grow">
                                                    <span class="font-semibold">{{
                                                        creator.superview.normalized.display_name
                                                    }}</span>
                                                    <span class="ml-auto text-sm text-emerald-600">Added</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <!-- duplicate creators -->
                                <li
                                    v-for="creator in results.resultsType === 'list'
                                        ? results.lists.duplicateCreators
                                        : results.campaigns.duplicateCreators"
                                    :key="creator._id"
                                    class="flex items-center border-b px-5 py-2"
                                >
                                    <div class="flex-grow">
                                        <div class="flex">
                                            <div class="relative mr-2 shrink-0 sm:mr-3">
                                                <Avatar
                                                    class="bg-slate-700 text-slate-50"
                                                    :src="creator.superview.normalized.avatar_url"
                                                    :username="creator.superview.normalized.display_name"
                                                    :size="32"
                                                />
                                                <div
                                                    v-if="creator.superview.platform_id === 'twitch'"
                                                    class="absolute -bottom-2 -right-1.5 z-10 flex rounded-full"
                                                >
                                                    <svg
                                                        width="22"
                                                        height="22"
                                                        fill="none"
                                                        class="flex-shrink-0 fill-current"
                                                    >
                                                        <path
                                                            fill="#fff"
                                                            d="M7.95243 3.33337 4.3999 6.8859v11.3999h2.85715v5.2093l5.20935-5.2093h1.9048l5.4573-5.4573V3.33337H7.95243Z"
                                                        />
                                                        <path
                                                            fill="#9147FF"
                                                            d="M8.78086 5.33337 6.3999 7.71433v8.57147h2.85714v2.3809l2.38096-2.3809h1.9048L17.8285 12V5.33337H8.78086Z"
                                                        />
                                                        <path
                                                            fill="#fff"
                                                            d="m14.9711 13.4286 1.9048-1.9048V6.28574H9.25684v7.14286h1.90476v1.9048l1.9048-1.9048h1.9047Z"
                                                        />
                                                        <path
                                                            fill="#9147FF"
                                                            d="M12.1143 8.19037h.9523v2.85713h-.9523V8.19037Zm3.3333 0v2.85713h-.9524V8.19037h.9524Z"
                                                        />
                                                    </svg>
                                                </div>
                                                <div
                                                    v-else-if="creator.superview.platform_id === 'youtube'"
                                                    class="absolute -bottom-0.5 -right-1 z-10 flex rounded-full"
                                                >
                                                    <svg height="16" width="16" viewBox="0 0 490 490">
                                                        <g>
                                                            <g>
                                                                <path
                                                                    fill="#EA4640"
                                                                    d="M480,180v130c0,55.195-44.805,100-100,100H110c-55.195,0-100-44.805-100-100V180
                                            c0-55.199,44.805-100,100-100h270C435.195,80,480,124.801,480,180z"
                                                                />
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <polygon
                                                                        style="fill: #ffffff"
                                                                        points="320,245 200,295 200,195 "
                                                                    />
                                                                </g>
                                                                <g>
                                                                    <path
                                                                        fill="white"
                                                                        d="M380,70H110C49.346,70,0,119.346,0,180v130c0,60.654,49.346,110,110,110h270
                                                c60.654,0,110-49.346,110-110V180C490,119.346,440.654,70,380,70z M470,310c0,49.626-40.374,90-90,90H110
                                                c-49.626,0-90-40.374-90-90V180c0-49.626,40.374-90,90-90h270c49.626,0,90,40.374,90,90V310z"
                                                                    />
                                                                    <path
                                                                        fill="white"
                                                                        d="M323.846,235.769l-120-50c-3.085-1.286-6.611-0.945-9.393,0.911
                                                c-2.782,1.854-4.453,4.977-4.453,8.32v100c0,3.344,1.671,6.466,4.453,8.32c1.667,1.112,3.601,1.68,5.548,1.68
                                                c1.301,0,2.608-0.254,3.845-0.769l120-50c3.727-1.553,6.154-5.194,6.154-9.231S327.572,237.322,323.846,235.769z M210,280v-70
                                                l84,35L210,280z"
                                                                    />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div class="flex grow">
                                                <div class="my-auto flex grow">
                                                    <span class="font-semibold">{{
                                                        creator.superview.normalized.display_name
                                                    }}</span>
                                                    <span class="ml-auto text-sm text-rose-600">Duplicate</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="!state.lists.isLoaded">
            <SkeletonAddToMenu />
        </div>

        <div v-else>
            <div class="px-5 pt-4">
                <p class="text-sm">Where do you want to add these creators to?</p>

                <div class="mt-3 flex max-h-[200px] flex-wrap overflow-y-auto">
                    <div v-for="superview in superviews" :key="superview._id" class="mb-1 mr-2">
                        <NamePlate
                            :logo="superview.normalized.avatar_url"
                            :name="superview.normalized.name"
                            :display-name="superview.normalized.display_name"
                            :platform-id="platformId"
                            :platform-user-id="superview._id"
                            :is-drawer-enabled="false"
                        />
                    </div>
                </div>
            </div>

            <div class="px-5 py-4">
                <div
                    class="inline-flex rounded bg-slate-200 p-1 shadow-sm hover:shadow-sm focus:shadow-sm"
                    role="group"
                >
                    <button
                        v-for="tab in tabs"
                        :key="tab"
                        class="flex rounded px-5 py-2.5 text-sm font-bold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                        :class="{
                            'bg-white': state.activeTab === tab,
                        }"
                        @click.prevent="setActiveTab(tab)"
                    >
                        <span class="capitalize">{{ tab }}</span>
                    </button>
                </div>

                <div v-if="state.activeTab !== 'recents'" class="mt-2 border-slate-200">
                    <div class="relative">
                        <label for="action-search" class="sr-only">Search</label>

                        <input
                            id="action-search"
                            v-model="state.searchText"
                            class="form-input w-full pl-9 shadow-none focus:border-slate-300"
                            type="search"
                            :placeholder="`Search for ${state.activeTab}`"
                            autocomplete="off"
                        />

                        <div class="group absolute inset-0 right-auto" aria-label="Search">
                            <svg
                                class="ml-3 mr-2 mt-2.5 h-4 w-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500"
                                viewBox="0 0 16 16"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
                                />
                                <path
                                    d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
                                />
                            </svg>
                        </div>
                    </div>
                </div>

                <div v-else-if="state.activeTab === 'recents'" class="invisible mt-2 border-slate-200">
                    <div class="relative">
                        <label for="action-search" class="sr-only">Search</label>

                        <input
                            id="action-search"
                            v-model="state.searchText"
                            class="form-input w-full pl-9 shadow-none focus:border-slate-300"
                            type="search"
                            :placeholder="`Search for ${state.activeTab}`"
                            autocomplete="off"
                        />

                        <div class="group absolute inset-0 right-auto" aria-label="Search">
                            <svg
                                class="ml-3 mr-2 mt-2.5 h-4 w-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500"
                                viewBox="0 0 16 16"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
                                />
                                <path
                                    d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
                                />
                            </svg>
                        </div>
                    </div>
                </div>

                <!-- Recent -->
                <div
                    v-if="state.activeTab === 'recents'"
                    class="mt-2 overflow-hidden overflow-y-auto rounded border border-slate-200"
                >
                    <div class="h-96 text-sm">
                        <div v-for="item in mappedRecents.items" :key="item.id" class="border-b border-slate-200">
                            <div v-if="item.type === 'supercampaign'">
                                <div
                                    class="group p-3 font-semibold"
                                    :class="{
                                        'bg-slate-50':
                                            state.activeSupercampaignId === mappedRecents.supercampaigns[item.id],
                                        'cursor-pointer hover:bg-slate-100': state.activeSupercampaignId !== item.id,
                                    }"
                                    @click="handleSuperCampaignClick(item.id)"
                                >
                                    <div class="flex">
                                        <div class="relative mr-3 h-16 w-12 shrink-0 rounded bg-slate-200">
                                            <img
                                                v-if="mappedRecents.supercampaigns[item.id].game"
                                                class="absolute h-full w-full rounded object-cover object-center"
                                                :src="`https://static-cdn.jtvnw.net/ttv-boxart/${
                                                    mappedRecents.supercampaigns[item.id].game.twitch_id
                                                }_IGDB-288x384.jpg`"
                                            />
                                            <div v-else class="flex h-full w-full">
                                                <span class="m-auto block text-center text-xs leading-4"
                                                    >No<br />Box<br />Art</span
                                                >
                                            </div>
                                        </div>

                                        <div class="my-auto">
                                            <div
                                                v-if="mappedRecents.supercampaigns[item.id].title"
                                                class="mb-1 line-clamp-2"
                                            >
                                                {{ mappedRecents.supercampaigns[item.id].title }}
                                            </div>
                                            <div
                                                class="inline-flex rounded bg-slate-100 px-2 py-1 text-center text-xs font-medium group-hover:bg-slate-200"
                                            >
                                                {{ mappedRecents?.supercampaigns?.[item.id]?.stage }}
                                            </div>
                                            <div
                                                class="ml-2 inline-flex rounded bg-slate-100 px-2 py-1 text-center text-xs font-medium group-hover:bg-slate-200"
                                            >
                                                Created
                                                {{
                                                    $filters.formatDate(
                                                        mappedRecents?.supercampaigns?.[item.id]?.created_at,
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-show="state.activeSupercampaignId === item.id">
                                    <div
                                        v-for="campaign in mappedRecents.supercampaigns[item.id].campaigns"
                                        :key="campaign.id"
                                        class="relative flex justify-between border-t border-slate-200 px-6 py-2"
                                    >
                                        <div class="flex">
                                            <div class="flex">
                                                <font-awesome-icon
                                                    class="my-auto mr-3 h-6 w-6"
                                                    :icon="getFromDictionary(`campaign.type.${campaign.type}`, 'icon')"
                                                />
                                            </div>

                                            <div class="flex flex-col">
                                                <span class="font-medium text-slate-600">{{
                                                    getFromDictionary(`campaign.type.${campaign.type}`, 'label')
                                                }}</span>
                                                <div v-if="campaign.started_at" class="text-xs">
                                                    {{ $filters.moment(campaign.started_at).format('MMM Do, YYYY') }}
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            v-if="campaign?.is_add_in_progress === true"
                                            class="btn btn-xs h-8 cursor-default bg-slate-500 text-white"
                                        >
                                            <font-awesome-icon
                                                class="my-auto mr-2 h-4 w-4 animate-spin"
                                                :icon="['fas', 'spinner-third']"
                                            />
                                            <span>Adding</span>
                                        </button>
                                        <button
                                            v-else-if="!isMulti && isCreatorAdded('campaign', campaign.id, item.id)"
                                            class="btn btn-xs h-8 cursor-not-allowed border border-slate-200 bg-slate-100 text-slate-600 shadow-none"
                                        >
                                            <font-awesome-icon class="my-auto mr-2 h-4 w-4" :icon="['fas', 'check']" />
                                            <span>Added</span>
                                        </button>
                                        <button
                                            v-else-if="
                                                !isMulti && isCreatorAlreadyAdded('campaign', campaign.id, item.id)
                                            "
                                            class="btn btn-xs h-8 cursor-not-allowed border border-slate-200 bg-slate-100 text-slate-600 shadow-none"
                                            disabled
                                        >
                                            <font-awesome-icon class="my-auto mr-2 h-4 w-4" :icon="['fas', 'check']" />
                                            <span>In Campaign</span>
                                        </button>

                                        <button
                                            v-else
                                            class="btn btn-xs my-auto h-8 bg-indigo-500 text-white hover:bg-indigo-600"
                                            @click="onCampaignSelect(campaign)"
                                        >
                                            <font-awesome-icon class="my-auto mr-2 h-4 w-4" :icon="['fas', 'plus']" />
                                            <span>Add</span>
                                        </button>
                                    </div>

                                    <div
                                        v-if="
                                            !mappedRecents.supercampaigns[item.id].campaigns ||
                                            !mappedRecents.supercampaigns[item.id].campaigns.length
                                        "
                                        class="w-full border-t border-slate-200 px-6 py-2 text-center"
                                    >
                                        <p>This campaign has no modules yet</p>
                                    </div>
                                </div>
                            </div>

                            <div v-else-if="item.type === 'list'">
                                <div class="px-5 py-3">
                                    <div class="flex items-center justify-between">
                                        <div class="flex">
                                            <div class="my-auto">
                                                <div
                                                    v-if="mappedRecents.lists[item.id].name"
                                                    class="line-clamp-2 font-semibold"
                                                >
                                                    {{ mappedRecents.lists[item.id].name }}
                                                </div>
                                                <div class="text-xs">
                                                    {{ mappedRecents.lists[item.id]?.totals?.total }} creators
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <button
                                                v-if="!isMulti && mappedRecents.lists[item.id].creatorIsOnList"
                                                class="btn btn-xs h-8 cursor-not-allowed border border-slate-200 bg-slate-100 text-slate-600 shadow-none"
                                            >
                                                <font-awesome-icon
                                                    class="my-auto mr-2 h-4 w-4"
                                                    :icon="['fas', 'check']"
                                                />
                                                <span>In List</span>
                                            </button>
                                            <button
                                                v-else-if="mappedRecents.lists[item.id]?.is_add_in_progress === true"
                                                class="btn btn-xs h-8 cursor-default bg-slate-500 text-white"
                                            >
                                                <font-awesome-icon
                                                    class="my-auto mr-2 h-4 w-4 animate-spin"
                                                    :icon="['fas', 'spinner-third']"
                                                />
                                                <span>Adding</span>
                                            </button>
                                            <button
                                                v-else
                                                class="btn btn-xs h-8 bg-indigo-500 text-white hover:bg-indigo-600"
                                                @click="addToList(mappedRecents.lists[item.id])"
                                            >
                                                <font-awesome-icon
                                                    class="my-auto mr-2 h-4 w-4"
                                                    :icon="['fas', 'plus']"
                                                />
                                                <span>Add</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!mappedRecents.total" class="py-3 text-center text-sm">No Recents</div>
                    </div>
                </div>

                <!-- Campaigns -->
                <div
                    v-if="state.activeTab === 'campaigns'"
                    class="mt-2 overflow-hidden overflow-y-scroll rounded border border-slate-200"
                >
                    <div class="h-96 divide-y divide-slate-200 text-sm">
                        <div v-for="supercampaign in filteredSupercampaigns" :key="supercampaign.id">
                            <div
                                class="group p-3 font-semibold"
                                :class="{
                                    'bg-slate-50': state.activeSupercampaignId === supercampaign.id,
                                    'cursor-pointer hover:bg-slate-100':
                                        state.activeSupercampaignId !== supercampaign.id,
                                }"
                                @click="handleSuperCampaignClick(supercampaign.id)"
                            >
                                <div class="flex">
                                    <div class="relative mr-3 h-16 w-12 shrink-0 rounded bg-slate-200">
                                        <img
                                            v-if="supercampaign.game"
                                            class="absolute h-full w-full rounded object-cover object-center"
                                            :src="`https://static-cdn.jtvnw.net/ttv-boxart/${supercampaign.game.twitch_id}_IGDB-288x384.jpg`"
                                        />
                                        <div v-else class="flex h-full w-full">
                                            <span class="m-auto block text-center text-xs leading-4"
                                                >No<br />Box<br />Art</span
                                            >
                                        </div>
                                    </div>

                                    <div class="my-auto">
                                        <div v-if="supercampaign.title" class="mb-1 line-clamp-2">
                                            {{ supercampaign.title }}
                                        </div>
                                        <div
                                            class="inline-flex rounded bg-slate-100 px-2 py-1 text-center text-xs font-medium group-hover:bg-slate-200"
                                        >
                                            {{ supercampaign?.stage }}
                                        </div>
                                        <div
                                            class="ml-2 inline-flex rounded bg-slate-100 px-2 py-1 text-center text-xs font-medium group-hover:bg-slate-200"
                                        >
                                            Created {{ $filters.formatDate(supercampaign?.created_at) }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-show="state.activeSupercampaignId === supercampaign.id">
                                <div
                                    v-for="campaign in supercampaign.campaigns"
                                    :key="campaign.id"
                                    class="relative flex justify-between border-t border-slate-200 px-6 py-2"
                                >
                                    <div class="flex">
                                        <div class="flex">
                                            <font-awesome-icon
                                                class="my-auto mr-3 h-6 w-6"
                                                :icon="getFromDictionary(`campaign.type.${campaign.type}`, 'icon')"
                                            />
                                        </div>

                                        <div class="flex flex-col">
                                            <span class="font-medium text-slate-600">{{
                                                getFromDictionary(`campaign.type.${campaign.type}`, 'label')
                                            }}</span>
                                            <div v-if="campaign.started_at" class="text-xs">
                                                {{ $filters.moment(campaign.started_at).format('MMM Do, YYYY') }}
                                            </div>
                                            <div v-else class="text-xs">No date set</div>
                                        </div>
                                    </div>
                                    <button
                                        v-if="campaign?.is_add_in_progress === true"
                                        class="btn btn-xs h-8 cursor-default bg-slate-500 text-white"
                                    >
                                        <font-awesome-icon
                                            class="my-auto mr-2 h-4 w-4 animate-spin"
                                            :icon="['fas', 'spinner-third']"
                                        />
                                        <span>Adding</span>
                                    </button>
                                    <button
                                        v-else-if="
                                            !isMulti && isCreatorAdded('campaign', campaign.id, supercampaign.id)
                                        "
                                        class="btn btn-xs h-8 cursor-not-allowed border border-slate-200 bg-slate-100 text-slate-600 shadow-none"
                                    >
                                        <font-awesome-icon class="my-auto mr-2 h-4 w-4" :icon="['fas', 'check']" />
                                        <span>Added</span>
                                    </button>
                                    <button
                                        v-else-if="
                                            !isMulti && isCreatorAlreadyAdded('campaign', campaign.id, supercampaign.id)
                                        "
                                        class="btn btn-xs h-8 cursor-not-allowed border border-slate-200 bg-slate-100 text-slate-600 shadow-none"
                                        disabled
                                    >
                                        <font-awesome-icon class="my-auto mr-2 h-4 w-4" :icon="['fas', 'check']" />
                                        <span>In Campaign</span>
                                    </button>
                                    <button
                                        v-else
                                        class="btn btn-xs my-auto h-8 bg-indigo-500 text-white hover:bg-indigo-600"
                                        @click="onCampaignSelect(campaign)"
                                    >
                                        <font-awesome-icon class="my-auto mr-2 h-4 w-4" :icon="['fas', 'plus']" />
                                        <span>Add</span>
                                    </button>
                                </div>

                                <div
                                    v-if="!supercampaign.campaigns || !supercampaign.campaigns.length"
                                    class="w-full border-t border-slate-200 px-6 py-2 text-center"
                                >
                                    <p>This campaign has no modules yet</p>
                                </div>
                            </div>
                        </div>

                        <div v-if="state.searchText && !filteredSupercampaigns.length" class="py-5 text-center text-sm">
                            No campaigns matching "{{ state.searchText }}"
                        </div>

                        <div
                            v-else-if="!supercampaigns.length || !filteredSupercampaigns.length"
                            class="py-5 text-center text-sm"
                        >
                            No campaigns found
                        </div>
                    </div>
                </div>

                <!-- Lists -->
                <div
                    v-if="state.activeTab === 'lists'"
                    class="mt-2 overflow-hidden overflow-y-scroll rounded border border-slate-200 scrollbar scrollbar-track-slate-200 scrollbar-thumb-slate-400 scrollbar-w-2 scrollbar-h-2 hover:scrollbar-thumb-slate-500 active:scrollbar-thumb-slate-600"
                >
                    <div class="h-96 divide-y divide-slate-200 text-sm">
                        <div class="px-5 py-3.5">
                            <div class="flex items-center justify-between">
                                <div class="flex">
                                    <div class="my-auto">
                                        <input
                                            v-model="newList.name"
                                            type="text"
                                            placeholder="Enter new list name"
                                            class="form-input w-full"
                                            :disabled="state.newList.isCreating === true"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <button
                                        class="btn btn-xs h-8"
                                        :class="{
                                            'pointer-events-none cursor-default bg-emerald-600':
                                                state.newList.isCreating === true,
                                            'pointer-events-none bg-slate-50 text-slate-400 opacity-50':
                                                !newList.name && state.newList.isCreating === false,
                                            'bg-emerald-500 text-white hover:bg-emerald-600': newList.name,
                                        }"
                                        :disable="!newList.name"
                                        @click="addToNewList"
                                    >
                                        <font-awesome-icon
                                            v-if="state.newList.isCreating === true"
                                            class="my-auto mr-2 h-4 w-4 animate-spin"
                                            :icon="['fas', 'spinner-third']"
                                        />
                                        <font-awesome-icon
                                            v-else
                                            class="my-auto mr-2 h-4 w-4"
                                            :icon="['fas', 'plus']"
                                        />
                                        <span>Add to new list</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div v-for="list in filteredLists" :key="list.id">
                            <div class="px-5 py-3">
                                <div class="flex items-center justify-between">
                                    <div class="flex">
                                        <div class="my-auto">
                                            <div v-if="list.name" class="line-clamp-2 font-semibold">
                                                {{ list.name }}
                                            </div>
                                            <div class="text-xs">
                                                {{ list.totals.total }} creators • Last updated
                                                {{ $filters.formatDate(list.updated_at) }}
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <button
                                            v-if="!isMulti && list.creatorIsOnList"
                                            class="btn btn-xs h-8 cursor-not-allowed border border-slate-200 bg-slate-100 text-gray-600 shadow-none"
                                        >
                                            <font-awesome-icon
                                                class="my-auto mr-1.5 h-4 w-4"
                                                :icon="['fas', 'check']"
                                            />
                                            <span>In List</span>
                                        </button>
                                        <button
                                            v-else-if="list?.is_add_in_progress === true"
                                            class="btn btn-xs h-8 cursor-default bg-slate-500 text-white"
                                        >
                                            <font-awesome-icon
                                                class="my-auto mr-2 h-4 w-4 animate-spin"
                                                :icon="['fas', 'spinner-third']"
                                            />
                                            <span>Adding</span>
                                        </button>
                                        <button
                                            v-else
                                            class="btn btn-xs h-8 bg-indigo-500 text-white hover:bg-indigo-600"
                                            @click="addToList(list)"
                                        >
                                            <font-awesome-icon class="my-auto mr-1.5 h-4 w-4" :icon="['fas', 'plus']" />
                                            <span>Add</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="state.searchText && !filteredLists.length" class="py-5 text-center text-sm">
                            No lists matching "{{ state.searchText }}"
                        </div>

                        <div v-else-if="!lists.length" class="py-5 text-center text-sm">No lists found</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import Avatar from '@/components/Avatar.vue'
import NamePlate from '@/components/plates/NamePlate.vue'
import SkeletonAddToMenu from '../skeletons/SkeletonAddToMenu.vue'

export default {
    components: {
        Avatar,
        NamePlate,
        SkeletonAddToMenu,
    },

    props: {
        superviews: Array,
        platformId: String,
        modalOpen: Boolean,
    },

    data() {
        return {
            state: {
                activeTab: 'recents',
                searchText: '',
                activeSupercampaignId: null,
                lists: {
                    isLoaded: false,
                },
                newList: {
                    isCreating: false,
                },
            },
            supercampaigns: [],
            lists: [],
            addToListStatus: {
                addedCreatorsMap: [],
                duplicateCreatorsMap: [],
            },
            addToCampaignStatus: {
                addedCreatorsMap: [],
                duplicateCreatorsMap: [],
            },
            results: {
                lists: {
                    addedCreators: [],
                    duplicateCreators: [],
                },
                campaigns: {
                    addedCreators: [],
                    duplicateCreators: [],
                },
                showResultsPage: false,
                resultsType: '',
                isLoading: false,
            },
            newList: {
                name: '',
                created: false,
            },
        }
    },

    computed: {
        ...mapGetters([
            'getFromDictionary',
            'recentCampaignOrListAdds',
            'isSfStaff',
            'selfOperator',
            'checkPermission',
        ]),

        tabs() {
            const tabs = ['recents']

            if (this.checkPermission('feature.campaigns.manage')) {
                tabs.push('campaigns')
            }

            tabs.push('lists')

            return tabs
        },

        // determines if modal adds multiple or single creators
        isMulti() {
            if (!this.superviews) return false

            return this.superviews.length > 1
        },

        filteredLists() {
            if (!this.lists || !this.lists.length || this.state.activeTab !== 'lists') return []

            const lists = _.orderBy(this.lists, ['updated_at'], ['desc'])

            if (this.state.searchText) {
                return _.filter(
                    lists,
                    (list) => list.name && list.name.toLowerCase().includes(this.state.searchText.toLowerCase()),
                )
            }

            return lists
        },

        filteredSupercampaigns() {
            if (!this.supercampaigns || !this.supercampaigns.length || this.state.activeTab !== 'campaigns') return []

            const searchLowerCase = this.state.searchText.toLowerCase()

            return _.filter(this.supercampaigns, (supercampaign) => {
                const title = supercampaign.title ? supercampaign.title.toLowerCase() : ''
                const gameName = supercampaign.game?.name ? supercampaign.game.name.toLowerCase() : ''
                return title.includes(searchLowerCase) || gameName.includes(searchLowerCase)
            })
        },

        mappedRecents() {
            const mapped = {
                lists: {},
                supercampaigns: {},
                total: 0,
                items: [],
            }

            _.forEach(this.recentCampaignOrListAdds, (item) => {
                if (item.type === 'list') {
                    const matchedList = _.find(this.lists, (list) => {
                        return list.id === item.id
                    })
                    if (matchedList && !mapped.lists[matchedList.id]) {
                        mapped.lists[matchedList.id] = matchedList
                        mapped.items.push(item)
                        mapped.total += 1
                    }
                } else if (item.type === 'supercampaign') {
                    const matchedCampaign = _.find(this.supercampaigns, (campaign) => {
                        return campaign.id === item.id
                    })
                    if (matchedCampaign && !mapped.supercampaigns[matchedCampaign.id]) {
                        mapped.supercampaigns[matchedCampaign.id] = matchedCampaign
                        mapped.items.push(item)
                        mapped.total += 1
                    }
                }
            })

            return mapped
        },
    },

    watch: {
        superviews() {
            this.onCloseModal()
            this.getSupercampaigns()
            this.state.lists.isLoaded = false
            this.getLists()
        },
    },

    created() {
        this.getSupercampaigns()
        this.state.lists.isLoaded = false
        this.getLists()

        if (!this.recentCampaignOrListAdds?.length) {
            this.state.activeTab = this.tabs[1]
        }
    },

    methods: {
        /*
            takes in a type (list or campaign) and an id (list.id or campaign.id) and returns true if the
            current creator was successfully added to the list or campaign, and false otherwise. If type is
            campaign, then provide a supercampaign_id as well. Only for SINGLE creators for now.
        */
        isCreatorAdded(type, id, supercampaignId = null) {
            if (this.isMulti) return false

            if (type === 'list') {
                const foundMap = this.addToListStatus.addedCreatorsMap.find((map) => map.list_id === id)

                if (foundMap) {
                    // eslint-disable-next-line no-underscore-dangle
                    return foundMap.creator_ids.includes(this.superviews[0]._id)
                }
            } else if (type === 'campaign') {
                const foundMap = this.addToCampaignStatus.addedCreatorsMap.find(
                    (map) => map.supercampaign_id === supercampaignId && map.campaign_id === id,
                )

                if (foundMap) {
                    // eslint-disable-next-line no-underscore-dangle
                    return foundMap.creator_ids.includes(this.superviews[0]._id)
                }
            }
            return false
        },

        /*
            takes in a type (list or campaign) and an id (list.id or campaign.id) and returns true if the
            current creator is already added to the list or campaign, and false otherwise. If type is
            campaign, then provide a supercampaign_id as well. Only for SINGLE creators for now.
        */
        isCreatorAlreadyAdded(type, id, supercampaignId = null) {
            if (this.isMulti) return false
            if (type === 'list') {
                const foundMap = this.addToListStatus.duplicateCreatorsMap.find((map) => map.list_id === id)

                if (foundMap) {
                    // eslint-disable-next-line no-underscore-dangle
                    return foundMap.creator_ids.includes(this.superviews[0]._id)
                }
            } else if (type === 'campaign') {
                const foundMap = this.addToCampaignStatus.duplicateCreatorsMap.find(
                    (map) => map.supercampaign_id === supercampaignId && map.campaign_id === id,
                )

                if (foundMap) {
                    // eslint-disable-next-line no-underscore-dangle
                    return foundMap.creator_ids.includes(this.superviews[0]._id)
                }
            }
            return false
        },

        addRecentItem(item) {
            const payload = {
                item,
            }

            this.$store.commit('recentCampaignOrListAdds:add', payload)
        },

        handleSuperCampaignClick(id) {
            if (id === this.state.activeSupercampaignId) {
                this.state.activeSupercampaignId = null
            } else {
                this.state.activeSupercampaignId = id
            }
        },

        async addToList(list) {
            this.state.searchText = ''

            const recentItem = {
                type: 'list',
                id: list.id,
            }

            this.addRecentItem(recentItem)
            return this.addCreatorsToList(list)
        },

        onCampaignSelect(campaign) {
            const supercampaign = _.find(this.supercampaigns, { id: campaign.parent_id })

            const recentItem = {
                type: 'supercampaign',
                id: supercampaign.id,
            }

            this.addRecentItem(recentItem)

            // api call
            this.addCreatorsToCampaign(campaign)
        },

        setActiveTab(tab) {
            if (tab === this.state.activeTab) return

            this.state.activeSupercampaignId = null
            this.state.activeTab = tab
            this.state.searchText = ''
        },

        // add creators to list API call
        addCreatorsToList(list) {
            list.is_add_in_progress = true

            return this.$axios
                .post(`/lists/${list.id}/creators`, {
                    creators: _.map(this.superviews, (superview) => ({
                        // eslint-disable-next-line no-underscore-dangle
                        platform_user_id: superview._id,
                        platform_id: this.platformId,
                    })),
                })
                .then(async ({ data }) => {
                    const { count, results } = data.payload

                    const insertions = _.flatten(_.map(results, 'new_creators'))
                    const duplicates = _.filter(
                        _.flatten(_.map(results, 'staged_insertions')),
                        (item) => item.status === 'duplicate',
                    )

                    // multi add handling
                    if (this.isMulti) {
                        // add added creatros to map
                        if (count > 0) {
                            const addedMap = {
                                list_id: list.id,
                                // eslint-disable-next-line no-underscore-dangle
                                creator_ids: _.map(insertions, 'superview._id'),
                            }
                            this.addToListStatus.addedCreatorsMap.push(addedMap)
                            this.results.lists.addedCreators = insertions
                        }

                        // add duplicate creators to map
                        if (duplicates?.length > 0) {
                            const duplicateMap = {
                                list_id: list.id,
                                creator_ids: _.map(duplicates, 'list_creator.platform_user_id'),
                            }

                            const duplicateSuperviews = duplicates.map((creator) => {
                                // eslint-disable-next-line no-underscore-dangle
                                const superview = this.superviews.find((view) => view._id === creator.platform_user_id)
                                if (superview) {
                                    return { superview: { ...superview } }
                                }
                                return null
                            })

                            this.addToListStatus.duplicateCreatorsMap.push(duplicateMap)
                            this.results.lists.duplicateCreators = duplicateSuperviews
                        }

                        this.results.resultsType = 'list'
                        this.results.showResultsPage = true
                    } else {
                        // single add handling
                        // eslint-disable-next-line no-lonely-if
                        if (count === 0) {
                            if (duplicates?.length > 0) {
                                const duplicateMap = {
                                    list_id: list.id,
                                    creator_ids: duplicates.map((creator) => creator.platform_user_id),
                                }

                                this.addToListStatus.duplicateCreatorsMap.push(duplicateMap)
                            }
                        } else if (count === 1) {
                            const addedMap = {
                                list_id: list.id,
                                // eslint-disable-next-line no-underscore-dangle
                                creator_ids: insertions.map((creator) => creator.superview._id),
                            }
                            this.addToListStatus.addedCreatorsMap.push(addedMap)
                        }
                    }

                    await this.getLists()

                    list.is_add_in_progress = false

                    return true
                })
                .catch((error) => {
                    this.$errorHandler(error, {
                        title: `Adding Creators`,
                        message:
                            error.response?.status === 400
                                ? error.response.data.message
                                : 'Error adding creator to list.',
                    })

                    list.is_add_in_progress = false
                })
        },

        // add creators to campaign API call
        addCreatorsToCampaign(campaign) {
            campaign.is_add_in_progress = true

            this.$axios
                .post(`/supercampaigns/${campaign.parent_id}/campaigns/${campaign.id}/participants`, {
                    creators: _.map(this.superviews, (superview) => ({
                        // eslint-disable-next-line no-underscore-dangle
                        platform_user_id: superview._id,
                        platform_id: this.platformId,
                    })),
                })
                .then(({ data }) => {
                    const { count, duplicateCampaignParticipants, participants } = data.payload

                    // multi add handling
                    if (this.isMulti) {
                        if (count > 0) {
                            // add added creators to map

                            const addedMap = {
                                campaign_id: campaign.id,
                                supercampaign_id: campaign.parent_id,
                                // eslint-disable-next-line no-underscore-dangle
                                creator_ids: participants.map((creator) => creator.superview._id),
                            }
                            this.addToCampaignStatus.addedCreatorsMap.push(addedMap)
                            this.results.campaigns.addedCreators = participants
                        } else if (duplicateCampaignParticipants && duplicateCampaignParticipants.length > 0) {
                            // add duplicate creators to map
                            const duplicateMap = {
                                campaign_id: campaign.id,
                                supercampaign_id: campaign.parent_id,
                                creator_ids: duplicateCampaignParticipants.map((creator) => creator.platform_user_id),
                            }

                            const duplicateSuperviews = duplicateCampaignParticipants.map((creator) => {
                                // eslint-disable-next-line no-underscore-dangle
                                const superview = this.superviews.find((view) => view._id === creator.platform_user_id)
                                if (superview) {
                                    return { superview: { ...superview } }
                                }
                                return null
                            })

                            this.addToCampaignStatus.duplicateCreatorsMap.push(duplicateMap)
                            this.results.campaigns.duplicateCreators = duplicateSuperviews
                        }

                        this.results.resultsType = 'campaign'
                        this.results.showResultsPage = true
                    } else {
                        // single add handling
                        // eslint-disable-next-line no-lonely-if
                        if (count === 0) {
                            // adding duplicate to map

                            if (duplicateCampaignParticipants && duplicateCampaignParticipants.length > 0) {
                                const duplicateMap = {
                                    campaign_id: campaign.id,
                                    supercampaign_id: campaign.parent_id,
                                    creator_ids: duplicateCampaignParticipants.map(
                                        (creator) => creator.platform_user_id,
                                    ),
                                }
                                this.addToCampaignStatus.duplicateCreatorsMap.push(duplicateMap)
                            }
                        } else if (count === 1) {
                            // adding creator to map

                            const addedMap = {
                                campaign_id: campaign.id,
                                supercampaign_id: campaign.parent_id,
                                creator_ids: participants.map((creator) => creator.platform_user_id),
                            }
                            this.addToCampaignStatus.addedCreatorsMap.push(addedMap)
                        }
                    }

                    campaign.is_add_in_progress = false
                })
                .catch((error) => {
                    console.log(error)

                    campaign.is_add_in_progress = false
                })
        },

        getLists() {
            let params = null

            if (this.superviews && this.superviews.length === 1) {
                params = {
                    platform_user_id: this.superviews[0]._id,
                    platform_id: this.platformId,
                }
            }

            return this.$axios
                .get('/lists', {
                    params,
                })
                .then(({ data }) => {
                    this.lists = _.orderBy(
                        [...data.payload.myLists, ...data.payload.sharedLists],
                        ['updated_at'],
                        ['desc'],
                    )
                    this.state.lists.isLoaded = true
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        getSupercampaigns() {
            this.$axios
                .get(`/supercampaigns`, {
                    params: {
                        relations: ['games', 'campaigns'],
                    },
                })
                .then(({ data }) => {
                    this.supercampaigns = _(data.payload.supercampaigns).orderBy(['created_at'], ['desc']).value()
                })
                .catch((error) => {
                    this.$errorHandler(error, {
                        title: `Supercampaigns`,
                        message: `Error fetching supercampaigns.`,
                    })
                })
        },

        addToNewList() {
            this.state.newList.isCreating = true

            const list = {
                name: this.newList.name,
                operator_id: this.selfOperator.id,
            }

            this.$axios
                .post('/lists', {
                    list,
                })
                .then(async ({ data }) => {
                    const newList = data.payload.list

                    this.lists.unshift(newList)

                    await this.addToList(newList)
                    this.newList.created = true
                    this.state.newList.isCreating = false
                    this.newList.name = ''
                    return this.$successHandler(`Successfully created list with selected creators.`)
                })
                .catch((error) => {
                    this.state.newList.isCreating = false
                    this.$errorHandler(error, {
                        title: 'Creating new list',
                        message: 'Error creating new list.',
                    })
                })
        },

        onCloseModal() {
            // reset state

            this.state = {
                activeTab: 'recents',
                searchText: '',
                activeSupercampaignId: null,
            }
            this.addToListStatus = {
                addedCreatorsMap: [],
                duplicateCreatorsMap: [],
            }
            this.addToCampaignStatus = {
                addedCreatorsMap: [],
                duplicateCreatorsMap: [],
            }
            this.results = {
                lists: {
                    addedCreators: [],
                    duplicateCreators: [],
                },
                campaigns: {
                    addedCreators: [],
                    duplicateCreators: [],
                },
                showResultsPage: false,
                resultsType: '',
                isLoading: false,
            }
        },
    },
}
</script>
