<template>
    <div>
        <!-- Sidebar backdrop (mobile only) -->
        <div
            class="fixed inset-0 z-40 bg-slate-900 bg-opacity-30 transition-opacity duration-200 lg:z-auto lg:hidden"
            :class="sidebarOpen ? 'opacity-100' : 'pointer-events-none opacity-0'"
            aria-hidden="true"
        />

        <!-- Sidebar -->
        <div
            id="sidebar"
            ref="sidebar"
            class="no-scrollbar absolute left-0 top-0 z-40 flex h-screen w-64 shrink-0 transform flex-col overflow-y-scroll bg-cinder-900 p-4 transition-all duration-200 ease-in-out lg:static lg:left-auto lg:top-auto lg:w-20 lg:translate-x-0 lg:overflow-y-auto lg:sidebar-expanded:!w-64 2xl:!w-64"
            :class="sidebarOpen ? 'translate-x-0' : '-translate-x-64'"
        >
            <!-- Sidebar header -->
            <div class="mb-10 flex justify-between pr-3 sm:px-2">
                <!-- Close button -->
                <button
                    ref="trigger"
                    class="text-slate-500 hover:text-slate-400 lg:hidden"
                    aria-controls="sidebar"
                    :aria-expanded="sidebarOpen"
                    @click.stop="$emit('close-sidebar')"
                >
                    <span class="sr-only">Close sidebar</span>
                    <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
                    </svg>
                </button>

                <!-- Logo -->
                <router-link class="" to="/">
                    <Logo
                        variant="light"
                        :has-text="sidebarExpanded"
                        class="2xl:hidden"
                        :class="{
                            'h-10 w-10': !sidebarExpanded,
                        }"
                    />
                    <Logo variant="light" :has-text="true" class="hidden 2xl:block" />
                </router-link>
            </div>

            <!-- Links -->
            <div class="space-y-8">
                <!-- Pages group -->
                <div>
                    <h3 class="pl-3 text-xs font-semibold uppercase text-slate-500">
                        <!-- <span class="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6" aria-hidden="true">•••</span> -->
                        <span class="lg:hidden lg:sidebar-expanded:block 2xl:block">Pages</span>
                    </h3>

                    <!-- Navigation -->
                    <ul class="mt-3">
                        <!-- Discover -->
                        <router-link
                            v-if="checkPermission('module.discovery')"
                            v-slot="{ href, navigate, isExactActive }"
                            :to="{ name: 'discovery' }"
                            custom
                        >
                            <li class="mb-0.5 rounded-sm px-3 py-2 last:mb-0" :class="isExactActive && 'bg-sf_panel'">
                                <a
                                    class="block cursor-pointer truncate text-slate-200 transition duration-150 hover:text-white"
                                    :class="isExactActive && 'hover:text-slate-200'"
                                    :href="href"
                                    @click="navigate"
                                >
                                    <div class="flex items-center">
                                        <font-awesome-icon
                                            fixed-width
                                            :icon="['fad', 'magnifying-glass']"
                                            class="h-6 w-6 shrink-0"
                                            :path-classes="['text-slate-400', 'text-slate-600']"
                                        />
                                        <span
                                            class="ml-3 text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100"
                                        >
                                            Discovery
                                        </span>
                                    </div>
                                </a>
                            </li>
                        </router-link>

                        <!-- Campaigns -->
                        <router-link
                            v-if="checkPermission('module.campaigns')"
                            v-slot="{ href, navigate, isExactActive }"
                            :to="{ name: 'campaigns' }"
                            custom
                        >
                            <li
                                class="mb-0.5 cursor-pointer rounded-sm px-3 py-2 last:mb-0"
                                :class="isExactActive && 'bg-sf_panel'"
                                @click="navigate"
                            >
                                <a
                                    class="block truncate text-slate-200 transition duration-150 hover:text-white"
                                    :class="isExactActive && 'hover:text-slate-200'"
                                    :href="href"
                                >
                                    <div class="flex items-center">
                                        <svg class="h-6 w-6 shrink-0" viewBox="0 0 24 24">
                                            <path
                                                class="fill-current text-slate-600"
                                                d="M20 7a.75.75 0 01-.75-.75 1.5 1.5 0 00-1.5-1.5.75.75 0 110-1.5 1.5 1.5 0 001.5-1.5.75.75 0 111.5 0 1.5 1.5 0 001.5 1.5.75.75 0 110 1.5 1.5 1.5 0 00-1.5 1.5A.75.75 0 0120 7zM4 23a.75.75 0 01-.75-.75 1.5 1.5 0 00-1.5-1.5.75.75 0 110-1.5 1.5 1.5 0 001.5-1.5.75.75 0 111.5 0 1.5 1.5 0 001.5 1.5.75.75 0 110 1.5 1.5 1.5 0 00-1.5 1.5A.75.75 0 014 23z"
                                            />
                                            <path
                                                class="fill-current text-slate-400"
                                                d="M17 23a1 1 0 01-1-1 4 4 0 00-4-4 1 1 0 010-2 4 4 0 004-4 1 1 0 012 0 4 4 0 004 4 1 1 0 010 2 4 4 0 00-4 4 1 1 0 01-1 1zM7 13a1 1 0 01-1-1 4 4 0 00-4-4 1 1 0 110-2 4 4 0 004-4 1 1 0 112 0 4 4 0 004 4 1 1 0 010 2 4 4 0 00-4 4 1 1 0 01-1 1z"
                                            />
                                        </svg>
                                        <span
                                            class="ml-3 text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100"
                                            >Campaigns</span
                                        >
                                    </div>
                                </a>
                            </li>
                        </router-link>

                        <!-- Lists -->
                        <router-link
                            v-if="checkPermission('module.lists')"
                            v-slot="{ href, navigate, isExactActive }"
                            :to="{ name: 'lists' }"
                            custom
                        >
                            <li
                                class="mb-0.5 cursor-pointer rounded-sm px-3 py-2 last:mb-0"
                                :class="isExactActive && 'bg-sf_panel'"
                                @click="navigate"
                            >
                                <a
                                    class="block truncate text-slate-200 transition duration-150 hover:text-white"
                                    :class="isExactActive && 'hover:text-slate-200'"
                                    :href="href"
                                >
                                    <div class="flex items-center">
                                        <font-awesome-icon
                                            fixed-width
                                            :icon="['fas', 'list']"
                                            class="h-6 w-6 shrink-0"
                                            :path-classes="['text-slate-400', 'text-slate-600']"
                                        />
                                        <span
                                            class="ml-3 text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100"
                                            >Lists</span
                                        >
                                    </div>
                                </a>
                            </li>
                        </router-link>

                        <!-- Messages-->
                        <router-link
                            v-if="checkPermission('module.messages')"
                            v-slot="{ href, navigate, isExactActive }"
                            :to="{ name: 'messages' }"
                            custom
                        >
                            <li
                                class="mb-0.5 cursor-pointer rounded-sm px-3 py-2 last:mb-0"
                                :class="isExactActive && 'bg-sf_panel'"
                                @click="navigate"
                            >
                                <a
                                    class="block truncate text-slate-200 transition duration-150 hover:text-white"
                                    :class="isExactActive && 'hover:text-slate-200'"
                                    :href="href"
                                >
                                    <div class="flex items-center">
                                        <font-awesome-icon
                                            fixed-width
                                            :icon="['fas', 'envelope']"
                                            class="h-6 w-6 shrink-0"
                                            :path-classes="['text-slate-400', 'text-slate-600']"
                                        />
                                        <span
                                            class="ml-3 text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100"
                                            >Messages</span
                                        >
                                    </div>
                                </a>
                            </li>
                        </router-link>

                        <!-- Games -->
                        <!--
                        <router-link
                            v-if="checkPermission('module.games')"
                            v-slot="{ href, navigate, isExactActive }"
                            :to="{ name: 'games' }"
                            custom
                        >
                            <li
                                class="mb-0.5 cursor-pointer rounded-sm px-3 py-2 last:mb-0"
                                :class="isExactActive && 'bg-sf_panel'"
                                @click="navigate"
                            >
                                <a
                                    class="block truncate text-slate-200 transition duration-150 hover:text-white"
                                    :class="isExactActive && 'hover:text-slate-200'"
                                    :href="href"
                                >
                                    <div class="flex items-center">
                                        <font-awesome-icon
                                            fixed-width
                                            :icon="['fas', 'gamepad-modern']"
                                            class="h-6 w-6 shrink-0"
                                            :path-classes="['text-slate-400', 'text-slate-600']"
                                        />
                                        <span
                                            class="ml-3 text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100"
                                            >Games</span
                                        >
                                    </div>
                                </a>
                            </li>
                        </router-link>
                        <li v-else class="mb-0.5 rounded-sm px-3 py-2 opacity-50 last:mb-0">
                            <a class="block truncate text-slate-200 transition duration-150 hover:text-white">
                                <div class="flex items-center">
                                    <font-awesome-icon
                                        fixed-width
                                        :icon="['fas', 'gamepad-modern']"
                                        class="h-6 w-6 shrink-0"
                                        :path-classes="['text-slate-400', 'text-slate-600']"
                                    />

                                    <div class="ml-3 flex flex-col">
                                        <span
                                            class="text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100"
                                        >
                                            Games
                                        </span>
                                        <span class="text-xs">Coming Soon!</span>
                                    </div>
                                </div>
                            </a>
                        </li>
                        -->

                        <template v-if="selfActiveOrganization && selfActiveOrganization.type === 'talent-agency'">
                            <!-- Roster -->
                            <router-link v-slot="{ href, navigate, isExactActive }" :to="{ name: 'roster' }" custom>
                                <li
                                    class="mb-0.5 cursor-pointer rounded-sm px-3 py-2 last:mb-0"
                                    :class="isExactActive && 'bg-sf_panel'"
                                    @click="navigate"
                                >
                                    <a
                                        class="block truncate text-slate-200 transition duration-150 hover:text-white"
                                        :class="isExactActive && 'hover:text-slate-200'"
                                        :href="href"
                                    >
                                        <div class="flex items-center">
                                            <font-awesome-icon
                                                fixed-width
                                                :icon="['fad', 'rectangle-vertical-history']"
                                                class="h-6 w-6 shrink-0"
                                                :path-classes="['text-slate-600', 'text-slate-400']"
                                            />
                                            <span
                                                class="ml-3 text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100"
                                                >Roster</span
                                            >
                                        </div>
                                    </a>
                                </li>
                            </router-link>

                            <!-- Bids -->
                            <router-link v-slot="{ href, navigate, isExactActive }" :to="{ name: 'bids' }" custom>
                                <li
                                    class="mb-0.5 rounded-sm px-3 py-2 last:mb-0"
                                    :class="isExactActive && 'bg-sf_panel'"
                                >
                                    <a
                                        class="block cursor-pointer truncate text-slate-200 transition duration-150 hover:text-white"
                                        :class="isExactActive && 'hover:text-slate-200'"
                                        :href="href"
                                        @click="navigate"
                                    >
                                        <div class="flex items-center">
                                            <font-awesome-icon
                                                fixed-width
                                                :icon="['fad', 'magnifying-glass']"
                                                class="h-6 w-6 shrink-0"
                                                :path-classes="['text-slate-400', 'text-slate-600']"
                                            />
                                            <span
                                                class="ml-3 text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100"
                                            >
                                                Bids
                                            </span>
                                        </div>
                                    </a>
                                </li>
                            </router-link>
                        </template>
                    </ul>
                </div>
            </div>

            <div class="mt-auto">
                <div class="justify-end">
                    <DropdownOrganization />
                </div>

                <!-- Expand / collapse button -->
                <div class="hidden justify-end pt-3 lg:inline-flex 2xl:hidden">
                    <div class="px-3 py-2">
                        <button @click.prevent="sidebarExpanded = !sidebarExpanded">
                            <span class="sr-only">Expand / collapse sidebar</span>
                            <svg class="h-6 w-6 fill-current sidebar-expanded:rotate-180" viewBox="0 0 24 24">
                                <path
                                    class="text-slate-400"
                                    d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                                />
                                <path class="text-slate-600" d="M3 23H1V1h2z" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ref, onMounted, onUnmounted, watch } from 'vue'
import { useRouter } from 'vue-router'

import Logo from '@/components/Logo.vue'
import DropdownOrganization from '@/components/dropdowns/DropdownOrganization.vue'

export default {
    name: 'Sidebar',
    components: {
        Logo,
        DropdownOrganization,
    },
    props: ['sidebarOpen'],
    setup(props, { emit }) {
        const trigger = ref(null)
        const sidebar = ref(null)

        const storedSidebarExpanded = localStorage.getItem('sidebar-expanded')
        const sidebarExpanded = ref(storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true')

        const currentRoute = useRouter().currentRoute.value

        // close on click outside
        const clickHandler = ({ target }) => {
            if (!sidebar.value || !trigger.value) return
            if (!props.sidebarOpen || sidebar.value.contains(target) || trigger.value.contains(target)) return
            emit('close-sidebar')
        }

        // close if the esc key is pressed
        const keyHandler = ({ keyCode }) => {
            if (!props.sidebarOpen || keyCode !== 27) return
            emit('close-sidebar')
        }

        onMounted(() => {
            document.addEventListener('click', clickHandler)
            document.addEventListener('keydown', keyHandler)
        })

        onUnmounted(() => {
            document.removeEventListener('click', clickHandler)
            document.removeEventListener('keydown', keyHandler)
        })

        watch(sidebarExpanded, () => {
            localStorage.setItem('sidebar-expanded', sidebarExpanded.value)
            if (sidebarExpanded.value) {
                document.querySelector('body').classList.add('sidebar-expanded')
            } else {
                document.querySelector('body').classList.remove('sidebar-expanded')
            }
        })

        return {
            trigger,
            sidebar,
            sidebarExpanded,
            currentRoute,
        }
    },
    computed: {
        ...mapGetters(['isSfStaff', 'selfActiveOrganization', 'checkPermission']),
    },
}
</script>
