<template>
    <CreatorActionModel ref="actionModal" EntityType="Message" @reload="onReload" />

    <UIDialog
        v-if="modals.messages_view.is_open"
        :open="modals.messages_view.is_open"
        @update:open="$store.commit('modal:close', 'messages_view')"
    >
        <DialogScrollContent :class="'max-w-2xl border-none p-0'">
            <DialogHeader class="border-b border-slate-100">
                <DialogTitle class="px-4 py-4"> View Message </DialogTitle>
            </DialogHeader>

            <div class="px-4 pb-5" v-html="modals.messages_view.message.payload"></div>
        </DialogScrollContent>
    </UIDialog>

    <div class="mx-auto w-full max-w-9xl p-3 md:p-4 lg:p-8">
        <div class="grid-cols-1 xl:grid">
            <div v-if="summary !== null" class="flex flex-wrap xl:h-12 xl:flex-nowrap">
                <div
                    v-for="tab in tabs"
                    :key="tab.key"
                    class="group flex w-full cursor-pointer select-none border bg-white px-4 py-3 text-left text-xs font-medium uppercase text-slate-500 first:rounded-bl first:rounded-tl first:border-l last:rounded-br last:rounded-tr last:border-r"
                    :class="{
                        'border-slate-200 hover:bg-slate-50': tab.key !== state.activeTab,
                        'border-b-slate-400': tab.key === state.activeTab,
                        '!cursor-default border-slate-400 border-b-slate-200 !bg-indigo-50':
                            tab.key === state.activeTab,
                    }"
                    @click="setActiveTab(tab.key)"
                >
                    <div class="flex grow justify-between">
                        <div class="my-auto flex">
                            <span class="leading-none">{{ tab.label }}</span>
                        </div>
                        <div class="my-auto">
                            {{ getTabRecordCount(tab.key) }}
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="flex h-12 w-full">
                <div class="mr-[1px] grow rounded-br border-b border-l border-r border-l-slate-400 bg-slate-50" />
            </div>
        </div>

        <div class="col-span-full mt-4 rounded-sm border border-slate-200 bg-white shadow-lg">
            <div class="p-3">
                <div class="">
                    <table v-if="!state.isLoading" class="w-full table-auto">
                        <thead class="rounded-sm bg-slate-50 text-left text-xs uppercase text-slate-400">
                            <tr>
                                <th class="w-7 whitespace-nowrap p-2 pl-3 pl-5">
                                    <div class="flex">
                                        <input
                                            v-model="state.allSelected"
                                            type="checkbox"
                                            class="form-checkbox"
                                            @change="onToggleSelectAll()"
                                        />
                                    </div>
                                </th>

                                <th class="cursor-pointer whitespace-nowrap p-2" @click="sortByField('message_type')">
                                    <div class="flex">
                                        <span class="font-semibold">Type</span>
                                    </div>
                                </th>

                                <th class="cursor-pointer whitespace-nowrap p-2" @click="sortByField('recipient_name')">
                                    <div class="flex">
                                        <span class="font-semibold">Recipient</span>
                                    </div>
                                </th>

                                <th class="cursor-pointer whitespace-nowrap p-2" @click="sortByField('subject')">
                                    <div class="flex">
                                        <span class="font-semibold">Subject</span>
                                    </div>
                                </th>

                                <th class="cursor-pointer whitespace-nowrap p-2" @click="sortByField('status')">
                                    <div class="flex">
                                        <span class="font-semibold">Status</span>
                                    </div>
                                </th>

                                <th
                                    v-if="state.activeTab !== 'sent'"
                                    class="cursor-pointer whitespace-nowrap p-2"
                                    @click="sortByField('created_at')"
                                >
                                    <div class="flex">
                                        <span class="font-semibold">Created At</span>
                                    </div>
                                </th>

                                <th
                                    v-if="state.activeTab === 'sent'"
                                    class="cursor-pointer whitespace-nowrap p-2"
                                    @click="sortByField('sent_at')"
                                >
                                    <div class="flex">
                                        <span class="font-semibold">Sent At</span>
                                    </div>
                                </th>

                                <th class="w-7 pb-3 pl-2 pr-5 pt-3">
                                    <div
                                        class="flex w-full rounded bg-slate-100 text-slate-600 transition duration-200 hover:bg-slate-200"
                                    >
                                        <MessageAction
                                            :actions="actions"
                                            :messages="state.selectedMessages"
                                            :show-count="false"
                                            @perform-action="onActionModalOpen"
                                        />
                                    </div>
                                </th>
                            </tr>
                        </thead>

                        <tbody
                            class="divide-y divide-slate-200 border-b border-slate-200 text-sm"
                            :class="{ 'pointer-events-none opacity-50': state.isBusy === true }"
                        >
                            <tr v-for="message in messages" :key="message.id" class="group">
                                <td class="whitespace-nowrap px-2 py-3.5 pl-5">
                                    <input
                                        v-model="state.selectedMessages"
                                        type="checkbox"
                                        class="form-checkbox"
                                        :value="message"
                                        @change="onToggleSelectOne()"
                                    />
                                </td>

                                <td class="whitespace-nowrap px-2 py-3.5">
                                    <div class="relative flex items-center">
                                        {{ getFromDictionary(`messages.type.${message.message_type}`, 'label') }}
                                    </div>
                                </td>

                                <td class="whitespace-nowrap px-2 py-3.5">
                                    <div class="relative flex items-center">
                                        {{ message.recipient_name }}
                                    </div>
                                </td>

                                <td class="whitespace-nowrap px-2 py-3.5">
                                    <div class="relative flex items-center">
                                        <div>{{ message.subject }}</div>

                                        <div
                                            class="absolute right-0 top-0 hidden h-full cursor-pointer rounded bg-slate-100 px-2 text-xs font-semibold transition duration-200 group-hover:flex hover:bg-slate-200"
                                            @click="
                                                $store.commit('modal:setData', {
                                                    modal_id: 'messages_view',
                                                    data: {
                                                        is_open: true,
                                                        message: message,
                                                    },
                                                })
                                            "
                                        >
                                            <span class="my-auto flex uppercase">
                                                <font-awesome-icon
                                                    :icon="['fas', 'rectangle-list']"
                                                    class="my-auto mr-1 h-4 w-4"
                                                />
                                                <span class="my-auto leading-none">Open</span>
                                            </span>
                                        </div>
                                    </div>
                                </td>

                                <td class="whitespace-nowrap px-2 py-3.5">
                                    <div class="relative flex items-center">
                                        {{ getFromDictionary(`messages.status.${message.status}`, 'label') }}
                                    </div>
                                </td>

                                <td v-if="state.activeTab !== 'sent'" class="whitespace-nowrap px-2 py-3.5">
                                    <div class="relative flex items-center">
                                        {{ $filters.formatDateAndTime(message.created_at) }}
                                    </div>
                                </td>

                                <td v-if="state.activeTab === 'sent'" class="whitespace-nowrap px-2 py-3.5">
                                    <div class="relative flex items-center">
                                        {{ $filters.formatDateAndTime(message.sent_at) }}
                                    </div>
                                </td>

                                <td class="w-7 px-2 pr-5">
                                    <div
                                        class="flex w-full rounded bg-slate-50 transition duration-200 hover:bg-slate-200"
                                    >
                                        <MessageAction
                                            :actions="actions"
                                            :messages="[message]"
                                            :show-count="false"
                                            @perform-action="onActionModalOpen"
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div v-if="!messages || (!messages.length && !state.isLoading)" class="w-full p-4 text-center">
                        <p class="text-sm font-medium">No messages to show</p>
                    </div>
                </div>
            </div>

            <div class="my-4 ml-4 mr-4">
                <DiscoveryPagination :paging="state.paging" record-name="messages" @paging-changed="onPagingChange" />
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { Dialog, DialogHeader, DialogTitle, DialogScrollContent } from '@/components/ui/dialog'
import DiscoveryPagination from '@/partials/tables/discovery/DiscoveryPagination.vue'
import MessageAction from '@/components/MessageAction.vue'
import CreatorActionModel from '@/partials/modals/CreatorActionModal.vue'
import messageAPI from '../utils/api/api.messages'

export default {
    name: 'MessagesPage',

    metaInfo: {
        title: 'Messages',
    },

    components: {
        UIDialog: Dialog,
        DialogHeader,
        DialogTitle,
        DialogScrollContent,
        DiscoveryPagination,
        MessageAction,
        CreatorActionModel,
    },

    data() {
        return {
            state: {
                isLoading: false,
                isBusy: false,
                activeTab: 'sending',
                paging: {
                    currentPage: 1,
                    totalRecords: 0,
                    perPage: 10,
                    sortby: 'created_at',
                    sortdir: 'desc',
                },
                selectedMessages: [],
                allSelected: false,
            },
            messages: [],
            summary: {
                sending: 0,
                sent: 0,
                cancelled: 0,
            },
            tabs: [
                { key: 'sending', label: 'Sending' },
                { key: 'sent', label: 'Sent' },
                { key: 'canceled', label: 'Canceled' },
            ],
            actions: [
                {
                    id: 'cancel-message',
                    label: 'Cancel messages',
                    status: ['sending'],
                    instructions: '',
                    options: [],
                    iconType: 'exit',
                },
            ],
        }
    },

    computed: {
        ...mapGetters(['getFromDictionary', 'selfOperator', 'selfActiveOrganizationId', 'modals']),
    },

    created() {
        this.getMessages()
    },

    methods: {
        async sortByField(column) {
            if (this.state.paging.sortby === column && this.state.paging.sortdir === 'desc') {
                this.state.paging.sortdir = 'asc'
            } else {
                this.state.paging.sortdir = 'desc'
            }
            this.state.paging.sortby = column
            this.getMessages()
        },

        setActiveTab(tab) {
            this.state.loading = true
            this.state.activeTab = tab
            this.state.paging.currentPage = 1
            this.getMessages()
        },

        getTabRecordCount(key) {
            return this.summary[key] ? this.summary[key] : 0
        },

        async getMessages() {
            this.state.loading = true
            this.messages = []

            const orgId = this.selfActiveOrganizationId
            const messageResult = await messageAPI.getMessages(
                this.$axios,
                orgId,
                this.state.activeTab,
                this.state.paging.currentPage,
                this.state.paging.perPage,
                this.state.paging.sortby,
                this.state.paging.sortdir,
            )

            if (messageResult.success) {
                this.state.paging.totalRecords = messageResult.value.paging.totalRecords
                this.state.paging.totalPages = Math.ceil(this.state.paging.totalRecords / this.state.paging.perPage)
                this.messages = messageResult.value.records
                this.summary = messageResult.value.summary
            }

            this.state.loading = false
        },

        onPagingChange(paging) {
            this.state.paging.currentPage = paging.currentPage ? paging.currentPage : this.state.paging.currentPage
            this.state.paging.perPage = paging.perPage ? paging.perPage : this.state.paging.perPage
            this.state.paging.totalPages = paging.totalPages ? paging.totalPages : this.state.paging.totalPages
            this.getMessages()
        },

        onActionModalOpen(action, selectedMessages) {
            this.$refs.actionModal.onActionModalOpen(action, selectedMessages)
        },

        onToggleSelectAll() {
            if (this.messages === 0) {
                return
            }

            if (this.state.allSelected) {
                this.state.selectedMessages = this.messages
            } else {
                this.state.selectedMessages = []
            }
        },

        onToggleSelectOne() {
            if (this.state.allSelected && !this.areAllSelected()) {
                this.state.allSelected = false
            }
        },

        areAllSelected() {
            const selectedIds = _.map(this.state.selectedMessages, (message) => message.id)
            const unselectedMessages = _.filter(this.messages, (message) => selectedIds.indexOf(message.id) === -1)
            return this.messages.length > 0 && unselectedMessages.length === 0
        },

        async onReload() {
            await this.getMessages()
        },
    },
}
</script>
