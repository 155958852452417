<template>
    <ModalBasic
        :title="getFromDictionary(`list.modal.type.${modal.type}`, 'title')"
        :modal-open="modal.isOpen"
        @close-modal="onListModalClose"
    >
        <!-- Modal content -->
        <div class="p-6">
            <div class="text-sm">
                <div v-if="modal.type === 'create'" class="mb-4">
                    <label class="mb-2 block text-sm font-medium" for="default">List Name</label>
                    <input
                        id="default"
                        v-model="values.name"
                        type="text"
                        class="form-input w-full"
                        placeholder="Enter list name"
                        @keyup.enter="onKeyupEnter"
                    />
                </div>

                <div v-if="modal.type === 'update'">
                    <label class="mb-2 block text-sm font-medium" for="default">Edit List Name</label>
                    <input
                        id="default"
                        v-model="values.name"
                        type="text"
                        class="form-input w-full"
                        :placeholder="currentList.name"
                        @keyup.enter="onKeyupEnter"
                    />
                </div>

                <div v-if="modal.type === 'delete'">
                    <p>
                        Are you sure you want to delete <span class="font-semibold">{{ currentList.name }}?</span>
                    </p>
                </div>
            </div>
        </div>
        <!-- Modal footer -->
        <div class="border-t border-slate-200 px-5 py-4">
            <div class="flex flex-wrap justify-end space-x-2">
                <button
                    class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300"
                    @click="modal.isOpen = false"
                >
                    Cancel
                </button>

                <button
                    class="btn-sm text-white"
                    :class="
                        modal.type === 'delete'
                            ? ['bg-rose-500', 'hover:bg-rose-600']
                            : ['bg-indigo-500', 'hover:bg-indigo-600']
                    "
                    @click="onListModalSubmit(modal.type, modal.list)"
                >
                    {{ getFromDictionary(`list.modal.type.${modal.type}`, 'actionLabel') }}
                </button>
            </div>
        </div>
    </ModalBasic>
    <div class="mx-auto w-full max-w-9xl p-3 md:p-4 lg:p-8">
        <div class="grid grid-cols-[285px,1fr] gap-1" style="grid-template-columns: 285px 1fr">
            <!-- Left Panel -->
            <div class="col-span-12 -ml-4 lg:col-span-1">
                <div>
                    <div class="sticky left-0 top-0 px-4">
                        <Tooltip
                            :show-tooltip="
                                getLimitation('lists.total') !== null &&
                                $filters.lodash.size(lists.user) >= getLimitation('lists.total')
                            "
                        >
                            <template #trigger>
                                <button
                                    class="btn mb-2 w-full bg-indigo-500 text-white hover:bg-indigo-600 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400"
                                    :disabled="
                                        getLimitation('lists.total') !== null &&
                                        $filters.lodash.size(lists.user) >= getLimitation('lists.total')
                                    "
                                    @click="openListModal('create')"
                                >
                                    <font-awesome-icon
                                        fixed-width
                                        :icon="['fas', 'plus']"
                                        class="h-4 w-4 shrink-0 opacity-50"
                                    />
                                    <span class="ml-2 lg:block">Create<span class="hidden xl:inline"> List</span></span>
                                </button>
                            </template>
                            <template #default>
                                <div class="whitespace-nowrap text-xs">
                                    {{
                                        getFromDictionary(`usage.lists.total`).replace(
                                            '[LIMIT]',
                                            getLimitation('lists.total'),
                                        )
                                    }}
                                </div>
                            </template>
                        </Tooltip>

                        <input
                            v-model="lists.filter.search"
                            class="form-input w-full"
                            type="text"
                            placeholder="Search for a list"
                            autocomplete="off"
                        />

                        <div class="mt-2 inline-flex w-full rounded bg-slate-200 p-1">
                            <button
                                class="flex grow rounded px-5 py-2 text-sm font-bold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                                :class="{
                                    'bg-white': state.listType === 'user',
                                }"
                                @click="setListType('user')"
                            >
                                <span class="mx-auto text-sm">My Lists</span>
                            </button>
                            <button
                                class="flex grow rounded px-5 py-2 text-sm font-bold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                                :class="{
                                    'bg-white': state.listType === 'shared',
                                }"
                                @click="setListType('shared')"
                            >
                                <span class="mx-auto text-sm">Shared With Me</span>
                            </button>
                        </div>
                    </div>

                    <div
                        class="mt-2 overflow-y-auto overflow-x-hidden px-4 pr-2 scrollbar scrollbar-thumb-slate-200 scrollbar-w-2 scrollbar-h-2 hover:scrollbar-thumb-slate-300 active:scrollbar-thumb-slate-400 md:max-h-[calc(100vh-254px)]"
                    >
                        <SkeletonUserLists v-if="!state.lists.isLoaded" />

                        <div v-else>
                            <div v-if="$filters.lodash.size(lists[state.listType])" class="space-y-2">
                                <div
                                    v-for="(list, index) in filteredLists[state.listType]"
                                    :key="`${list.name}-${index}`"
                                    @click="onListSelect(list)"
                                >
                                    <div
                                        class="cursor-pointer rounded border border-slate-200 bg-slate-50 px-4 py-4 transition duration-200"
                                        :class="
                                            currentList.id === list.id
                                                ? 'rounded !border-indigo-200 bg-white'
                                                : 'hover:bg-white'
                                        "
                                    >
                                        <span class="block font-semibold">{{ list.name }}</span>
                                        <span class="block text-sm">{{ list?.totals?.total }} creators</span>
                                        <span class="text-xs">Updated {{ $moment(list.updated_at).fromNow() }}</span>

                                        <div v-if="list.id === currentList.id" class="mt-2 text-sm">
                                            <button
                                                class="mr-4 font-medium text-indigo-500 hover:text-indigo-700"
                                                @click="openListModal('update', list)"
                                            >
                                                Edit
                                            </button>
                                            <button
                                                class="font-medium text-red-500 hover:text-red-700"
                                                @click="openListModal('delete', list)"
                                            >
                                                Delete
                                            </button>
                                        </div>

                                        <div
                                            v-if="list.platforms"
                                            class="mt-4 flex flex-col divide-y divide-slate-200 text-xs"
                                        >
                                            <div
                                                v-for="{ key, total } in list.platforms"
                                                :key="key"
                                                class="flex bg-slate-100 px-2.5 py-1.5 first:rounded-tl first:rounded-tr last:rounded-bl last:rounded-br"
                                            >
                                                <font-awesome-icon
                                                    :icon="['fab', key]"
                                                    class="my-auto h-3.5 w-3.5"
                                                    :class="`text-${key}`"
                                                />
                                                <div class="text-semibold my-auto ml-1.5 capitalize leading-none">
                                                    {{ key }}
                                                </div>
                                                <div class="ml-auto">{{ total }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-if="lists.filter.search && !filteredLists[state.listType]?.length"
                                    class="overflow-hidden break-words py-2 text-center text-sm"
                                >
                                    None of your lists match "{{ lists.filter.search }}"
                                </div>
                            </div>

                            <div v-if="!lists[state.listType] || $filters.lodash.size(lists[state.listType]) == 0">
                                <div class="overflow-hidden break-words py-2 text-center text-sm">No lists found</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-if="
                    state.lists.isLoaded &&
                    (!lists.user || $filters.lodash.size(lists.user) == 0) &&
                    (!lists.shared || $filters.lodash.size(lists.shared) == 0)
                "
            >
                <div class="flex rounded-sm border border-slate-200 bg-white px-3 py-3">
                    <h5 class="text-left text-lg text-sm font-medium leading-5 text-slate-500">
                        No lists have been created yet, please create one using the "Create list" option.
                    </h5>
                </div>
            </div>
            <div v-else class="col-span-12 overflow-hidden lg:col-span-1">
                <ListsCreatorTable
                    :creators="creators"
                    :current-list="currentList"
                    :potential-shares="potentialShares"
                    :default-platform-id="state.platformId"
                    :supercampaigns="supercampaigns"
                    @add-creators="onAddCreators"
                    @edit-creators="onCreatorsEdit"
                    @on-list-share="onListShareClick"
                    @bulk-import-creators="onBulkImportCreators"
                    @fetch-shared-to="onFetchSharedTo"
                />
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import ModalBasic from '@/components/ModalBasic.vue'
import SkeletonUserLists from '@/partials/skeletons/SkeletonUserLists.vue'
import ListsCreatorTable from '@/partials/tables/creator-lists/ListsCreatorTable.vue'
import Tooltip from '@/components/Tooltip.vue'
import { useBackgroundActions } from '@/composables/useBackgroundActions'

export default {
    name: 'ListsPage',

    metaInfo: {
        title: 'Lists',
    },

    components: {
        ListsCreatorTable,
        SkeletonUserLists,
        ModalBasic,
        Tooltip,
    },

    setup() {
        const { backgroundActions, addBackgroundAction, updateBackgroundAction, completeBackgroundAction } =
            useBackgroundActions()

        return {
            backgroundActions,
            addBackgroundAction,
            updateBackgroundAction,
            completeBackgroundAction,
        }
    },

    data() {
        return {
            currentList: {},
            selectedListId: null,
            creators: [],
            newCreators: [],
            supercampaigns: [],

            state: {
                listType: 'user',
                lists: {
                    isLoaded: false,
                },
                creators: {
                    isLoaded: false,
                },
                platformId: 'youtube',
            },

            lists: {
                user: [],
                shared: [],
                filter: {
                    type: 'user',
                    search: '',
                },
            },

            potentialShares: {
                operators: [],
                organizations: [],
            },

            modal: {
                isOpen: false,
                type: 'update',
                list: null,
            },

            values: {
                name: '',
            },
        }
    },

    computed: {
        ...mapGetters(['getFromDictionary', 'selfOperator', 'getLimitation', 'backgroundActions']),

        filteredLists() {
            const hasSearch = this?.lists?.filter?.search
            const searchKeyword = this.lists.filter.search.toLowerCase().trim()

            return _.mapValues(this.lists, (list) => {
                const filtered = _.filter(list, (item) => {
                    if (!hasSearch) return true
                    if (!item?.name) return false
                    return item.name.toLowerCase().includes(searchKeyword)
                })

                const ordered = _.orderBy(filtered, ['updated_at'], ['desc'])

                return ordered
            })
        },
    },

    created() {
        this.getLists().then(() => {
            if (this.$route.query.list_id) {
                const activeListId = this.$route.query.list_id

                const allLists = [...this.lists.user, ...this.lists.shared]

                const activeList = allLists.find((list) => list.id === activeListId)

                if (activeList) {
                    this.onListSelect(activeList)
                    return
                }
            }

            const firstList = _.first(_.orderBy(this.lists.user, ['updated_at'], ['desc']))
            if (firstList) this.onListSelect(firstList)
        })

        this.getSupercampaigns()
        this.getOrganizations()
    },

    methods: {
        ...mapActions(['addBackgroundAction', 'updateBackgroundAction', 'completeBackgroundAction']),

        setListType(type) {
            this.state.listType = type
        },

        setActivePlatform(activeList) {
            const biggestPlatform = _(activeList.totals)
                .map((value, key) => ({ key, value }))
                .filter((item) => item.key !== 'total')
                .maxBy('value')

            this.state.platformId = biggestPlatform?.key ?? 'youtube'
        },

        getLists() {
            return this.$axios
                .get('/lists')
                .then(({ data }) => {
                    _.forEach(data.payload.myLists, (list) => {
                        // eslint-disable-next-line no-param-reassign
                        list.type = 'user'
                    })
                    _.forEach(data.payload.sharedLists, (list) => {
                        // eslint-disable-next-line no-param-reassign
                        list.type = 'shared'
                    })

                    this.lists.user = data.payload.myLists
                    this.lists.shared = data.payload.sharedLists

                    const allLists = [...this.lists.user, ...this.lists.shared]

                    _.forEach(allLists, (list) => {
                        if (this.selectedListId && this.selectedListId === list.id) {
                            this.currentList = list
                        }

                        list.platforms = _.reduce(
                            list.totals,
                            (result, total, key) => {
                                if (key !== 'total' && total > 0) result.push({ key, total })
                                return result
                            },
                            [],
                        )
                    })

                    this.state.lists.isLoaded = true
                })
                .catch((error) => {
                    this.$errorHandler(error, {
                        title: `Lists`,
                        message: `Error fetching lists.`,
                    })
                })
        },

        openListModal(type, list) {
            this.modal.isOpen = true
            this.modal.type = type
            this.modal.list = list
            this.values.name = ''

            if (type === 'update') {
                this.values.name = list.name
            }
        },

        onListModalClose() {
            this.modal.isOpen = false
            this.modal.list = null
            this.values.name = ''
        },

        onListSelect(list) {
            if (this.selectedListId === list.id) return

            this.setActivePlatform(list)
            this.selectedListId = list.id
            this.currentList = list

            this.$router.push({
                query: {
                    ...this.$route.query,
                    list_id: _.get(list, 'id'),
                },
            })
        },

        isShared(list, { operatorId, organizationId }) {
            if (list) {
                if (operatorId && list.operators && list.operators.length) {
                    return !!_.find(list.operators, (operator) => operator.id === operatorId)
                }

                if (organizationId && list.organizations && list.organizations.length) {
                    return !!_.find(list.organizations, (organization) => organization.id === organizationId)
                }
            }

            return false
        },

        onListShareClick({ list, type, sharedOrganization, sharedOperator }) {
            if (type === 'add') {
                this.$axios
                    .post(`/lists/${list.id}/share`, {
                        organization_id: sharedOrganization?.id,
                        operator_id: sharedOperator?.id,
                    })
                    .then(() => {
                        if (!list.sharedTo) {
                            list.sharedTo = {
                                operators: [],
                                organizations: [],
                            }
                        }

                        if (sharedOrganization) {
                            if (!_.includes(list.sharedTo.organizations, sharedOrganization.id)) {
                                list.sharedTo.organizations.push(sharedOrganization.id)
                            }
                            this.$successHandler(`Successfully shared list with ${sharedOrganization.name}`)
                        }

                        if (sharedOperator) {
                            if (!_.includes(list.sharedTo.operators, sharedOperator.id)) {
                                list.sharedTo.operators.push(sharedOperator.id)
                            }
                            this.$successHandler(`Successfully shared list with ${sharedOperator.name}`)
                        }
                    })
                    .catch((error) => {
                        this.$errorHandler(error, {
                            title: `List Access`,
                            message: `Error while updating list access.`,
                        })
                    })
            }

            if (type === 'remove') {
                this.$axios
                    .delete(`/lists/${list.id}/share`, {
                        data: {
                            organization_id: sharedOrganization?.id,
                            operator_id: sharedOperator?.id,
                        },
                    })
                    .then(() => {
                        if (list.sharedTo) {
                            if (sharedOrganization) {
                                if (_.includes(list.sharedTo.organizations, sharedOrganization.id)) {
                                    const organizationIndex = _.findIndex(
                                        list.sharedTo.organizations,
                                        (organization) => organization === sharedOrganization.id,
                                    )
                                    list.sharedTo.organizations.splice(organizationIndex, 1)
                                }
                                this.$successHandler(`Successfully removed from list.`)
                            }

                            if (sharedOperator) {
                                if (_.includes(list.sharedTo.operators, sharedOperator.id)) {
                                    const operatorIndex = _.findIndex(
                                        list.sharedTo.operators,
                                        (operator) => operator === sharedOperator.id,
                                    )
                                    list.sharedTo.operators.splice(operatorIndex, 1)
                                }
                                this.$successHandler(`Successfully removed from list.`)
                            }
                        }
                    })
                    .catch((error) => {
                        this.$errorHandler(error, {
                            title: `List Access`,
                            message: `Error while updating list access.`,
                        })
                    })
            }
        },

        getSupercampaigns() {
            this.$axios
                .get(`/supercampaigns`, {
                    params: {
                        relations: ['games', 'campaigns'],
                    },
                })
                .then(({ data }) => {
                    this.supercampaigns = _(data.payload.supercampaigns).orderBy(['created_at'], ['desc']).value()
                })
                .catch((error) => {
                    this.$errorHandler(error, {
                        title: `Supercampaigns`,
                        message: `Error fetching supercampaigns.`,
                    })
                })
        },

        getOrganizations() {
            this.$axios
                .get(`/organizations`, {
                    params: {
                        relations: ['operators'],
                    },
                })
                .then(({ data }) => {
                    this.potentialShares.organizations = data.payload.organizations

                    const organizationsById = _.keyBy(data.payload.organizations, 'id')

                    const destructuredOperators = _.reduce(
                        data.payload.organizations,
                        (result, organization) => {
                            _.forEach(organization.operators, (operator) => {
                                // This will remove the current operator from the result set
                                if (operator.id === this.selfOperator.id) return

                                if (result[operator.id]) {
                                    result[operator.id].push(operator)
                                } else {
                                    // eslint-disable-next-line no-param-reassign
                                    result[operator.id] = [operator]
                                }
                            })

                            return result
                        },
                        {},
                    )

                    this.potentialShares.operators = _.map(destructuredOperators, (operatorEntries) => {
                        // This is creating a new object because _.omit
                        const firstOperator = _.omit(_.first(operatorEntries), ['organization_operator'])

                        return _.assign(firstOperator, {
                            organizations: _.map(operatorEntries, (entry) => {
                                const organization = _.omit(
                                    organizationsById[entry.organization_operator.organization_id],
                                    ['operators'],
                                )

                                return _.assign(organization, {
                                    role: entry.organization_operator.role,
                                })
                            }),
                        })
                    })
                })
                .catch((error) => {
                    this.$errorHandler(error, {
                        title: `Organizations`,
                        message: `Error fetching organizations.`,
                    })
                })
        },

        onListModalSubmit(type, currentList) {
            this.modal.list = null

            if (type === 'create') {
                const list = {
                    name: this.values.name,
                    operator_id: this.selfOperator.id,
                }

                this.$axios
                    .post('/lists', {
                        list,
                    })
                    .then(({ data }) => {
                        data.payload.list.totals = {
                            total: 0,
                        }
                        // Need to set as a user's list
                        /* eslint-disable no-param-reassign */
                        data.payload.list.type = 'user'
                        data.payload.list.creators = []
                        /* eslint-enable no-param-reassign */

                        this.onListSelect(data.payload.list)
                        this.onListModalClose()
                        this.lists.user.push(data.payload.list)
                        this.$successHandler(`Successfully created list.`)
                    })
                    .catch((error) => {
                        this.$errorHandler(error, {
                            title: `Creating List`,
                            message: 'Error creating new list.',
                        })
                    })
            } else if (type === 'update') {
                this.modal.isOpen = false

                const list = {
                    name: this.values.name,
                }

                this.$axios
                    .put(`/lists/${currentList.id}`, {
                        list,
                    })
                    .then(({ data }) => {
                        const oldList = _.find(this.lists.user, (item) => item.id === this.currentList.id)
                        _.assign(oldList, data.payload.list)

                        this.$successHandler(`Successfully updated list.`)
                    })
                    .catch((error) => {
                        this.$errorHandler(error, {
                            title: `Updating List`,
                            message: 'Error updating this list.',
                        })
                    })
            } else if (type === 'delete') {
                this.modal.isOpen = false

                this.$axios
                    .delete(`/lists/${currentList.id}`)
                    .then(() => {
                        this.lists.user = _.filter(this.lists.user, (item) => item.id !== currentList.id)
                        const firstList = _.first([...this.lists.user, ...this.lists.shared])

                        if (!firstList) {
                            this.currentList = null
                            this.creators = []
                        } else {
                            this.onListSelect(firstList)
                        }

                        this.$successHandler(`Successfully deleted list.`)
                    })
                    .catch((error) => {
                        this.$errorHandler(error, {
                            title: `Deleting List`,
                            message: 'Error deleting this list.',
                        })
                    })
            }
        },

        onCreatorsEdit({ type, creators, campaignId, supercampaignId }) {
            if (type === 'remove-selected') {
                const body = {
                    creators,
                }

                this.$axios
                    .delete(`/lists/${this.currentList.id}/creators`, {
                        data: body,
                    })
                    .then(({ data }) => {
                        const removedCreatorsLookup = _.keyBy(creators, 'platform_user_id')

                        this.creators = _.filter(
                            this.creators,
                            (creator) => !removedCreatorsLookup[creator.list_creator.platform_user_id],
                        )

                        this.getLists()

                        this.$successHandler(`Successfully removed ${data.payload.count} creators from list.`)
                    })
                    .catch((error) => {
                        this.$errorHandler(error, {
                            title: `Removing Creators`,
                            message: 'Error removing creators from list.',
                        })
                    })
            }

            if (type === 'add-selected') {
                const body = {
                    creators,
                }

                this.$axios
                    .post(`/supercampaigns/${supercampaignId}/campaigns/${campaignId}/participants`, body)
                    .then(() => {
                        this.$successHandler('Successfully added creators.')
                        this.getLists()
                    })
                    .catch((error) => {
                        this.$errorHandler(error, {
                            title: `Adding Creators`,
                            message: 'Error adding creators to list.',
                        })
                    })
            }

            if (type === 'add-all') {
                const body = {
                    creators,
                }

                this.$axios
                    .post(`/supercampaigns/${supercampaignId}/campaigns/${campaignId}/participants`, body)
                    .then(() => {
                        this.$successHandler('Successfully added all creators.')
                        this.getLists()
                    })
                    .catch((error) => {
                        this.$errorHandler(error, {
                            title: `Adding Creators`,
                            message: 'Error adding all creators to campaign.',
                        })
                    })
            }
        },

        onListOption(type) {
            this.lists.filter.type = type
            this.lists.filter.search = ''
        },

        async onAddCreators({ creators }) {
            this.state.creators.isAdding = true

            const body = {
                creators,
            }

            // Start the background action
            const actionId = await this.addBackgroundAction({
                description: `Adding ${creators.length} creators to ${this.currentList.name}`,
                estimatedDuration: creators.length * 10_000,
            })

            this.$axios
                .post(`/lists/${this.currentList.id}/creators`, body)
                .then(({ data }) => {
                    const newCreatorsCount = data?.payload?.results?.new_creators?.length || 0

                    if (newCreatorsCount > 0) {
                        this.creators.push(...data.payload.results.new_creators)

                        // Update and complete the background action
                        this.updateBackgroundAction(actionId, {
                            description: `Added ${newCreatorsCount} creators to list "${this.currentList.name}"`,
                        })

                        this.getLists()
                    } else {
                        // Update and complete the background action
                        this.updateBackgroundAction(actionId, {
                            description: `No new creators were added to list "${this.currentList.name}" as they were either duplicates or there was an issue with their profile.`,
                        })
                    }

                    this.completeBackgroundAction(actionId)

                    // this.$successHandler(`Successfully added ${newCreatorsCount} creators to list!`)

                    this.state.creators.isAdding = false
                })
                .catch(() => {
                    this.state.creators.isAdding = false

                    // Update the background action to show the error
                    this.updateBackgroundAction(actionId, {
                        description: `Error adding creators to ${this.currentList.name}`,
                    })
                    this.completeBackgroundAction(actionId)

                    // this.$errorHandler(error, {
                    //     title: `Adding Creators`,
                    //     message:
                    //         error.response?.status === 400
                    //             ? error.response.data.message
                    //             : 'Error adding creators to list.',
                    // })
                })
        },

        onBulkImportCreators(newCreators) {
            console.log('onBulkImportCreators', newCreators)

            this.creators.push(...newCreators)

            // Update the counts
            _(newCreators)
                .groupBy('list_creator.platform_id')
                .forEach((creators, platformId) => {
                    this.currentList.totals[platformId] = Number(this.currentList.totals) + _.size(creators)
                })
            this.currentList.totals.total = Number(this.currentList.totals.total) + _.size(newCreators)

            // handle success but some creators may not have been added
            this.$successHandler('Successfully bulk imported creators to list.')
        },

        onFetchSharedTo() {
            if (!this.currentList) {
                return null
            }

            if (this.currentList.sharedTo) {
                return null
            }

            return this.$axios
                .get(`/lists/${this.currentList.id}/share`)
                .then(({ data }) => {
                    this.currentList.sharedTo = {
                        organizations: data.payload.organizations,
                        operators: data.payload.operators,
                    }
                })
                .catch(() => {})
        },
    },
}
</script>
